import React, { useEffect } from "react";
import Header from "../components/header/Header";
import { useState } from "react";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import { DatePicker } from "reactstrap-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-dropdown-select";

const AddCenter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state;
  const { auth } = useAuth();
  console.log(auth);
  const [center, setcenter] = useState(data?.center || "");
  const [tehsil, settehsil] = useState(data?.tehsil || "");
  const [district, setdistrict] = useState(data?.district || "");
  const [centerId, setcenterId] = useState(data?.centerId || "");
  const [staffName, setstaffName] = useState(data?.staffName || "");
  const [staffId, setstaffId] = useState(data?.staffId || "");
  let s;
  if (data?.paymentDate) {
    s = new Date(data?.paymentDate);
  }
  const [paymentDate, setpaymentDate] = useState(s || new Date());
  const [isSubmit, setisSubmit] = useState(false);

  function generateCenterID() {
    const prefix = "GROUP_";
    const randomNum = Math.floor(Math.random() * 1000) + 100; // Generate a random number between 100 and 999
    return `${prefix}${randomNum}`;
  }

  useEffect(() => {
    const employeeList = async () => {
      try {
        const response = await axios.get("/users/list", {
          headers: { authorization: "bearer " + auth.token },
        });
        setemployeeList(response?.data?.list);
        if (auth?.user?.role == "employee") {
          setstaffId(auth?.user?.staffId);
          setstaffName(auth?.user?.name);
        }
        if (!centerId) {
          setcenterId(generateCenterID);
        }
      } catch (err) {
        console.error(err);
      }
    };
    employeeList();
  }, []);

  const [employeeList, setemployeeList] = useState([]);

  const submitForm = async () => {
    console.log(staffId, staffName);

    if (
      !center ||
      !tehsil ||
      !district ||
      !centerId ||
      !staffName ||
      !staffId ||
      !paymentDate
    ) {
      return swal({
        title: "चेतावनी!",
        text: `
          कृपया ग्रुप का सारा विवरण भरें`,
        icon: "warning",
      });
    }

    setisSubmit(true);
    try {
      const id = data?._id;
      const url = id ? "/center/" + id : "/center/add";
      const response = await axios.post(
        url,
        {
          center,
          tehsil,
          district,
          centerId,
          staffName,
          staffId,
          paymentDate,
        },
        {
          headers: { authorization: "bearer " + auth.token },
        }
      );
      console.log(response);
      if (response?.data?.success) {
        swal({
          title: "Success!",
          text: `${response.data.message}`,
          icon: "success",
        });

        navigate("/center/list");
      } else {
        swal({
          title: "Failure!",
          text: `${response.data.message}`,
          icon: "error",
        });
      }
    } catch (err) {
      swal({ title: "Error!", text: `${err.message}`, icon: "error" });

      console.log("this is called " + err);
    } finally {
      setisSubmit(false);
    }
  };

  const handleClose = async () => {
    navigate("/");
  };

  return (
    <>
      <Header page="Add Group" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Create New Group</h3>
              </div>
              <form className="needs-validation" noValidate>
                <div className="card-body">
                  <div className="form-group">
                    <h3
                      className="card-header"
                      style={{
                        fontFamily: "monospace",
                        fontWeight: "bolder",
                      }}
                    >
                      ग्रुप का विवरण
                    </h3>
                    <br />
                    <div className="row" id="2">
                      <div className="col-md-3">
                        <label htmlFor="name">ग्रुप का नाम :</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="ग्रुप का नाम"
                          value={center}
                          onChange={(e) => setcenter(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="username">तहसील: </label>
                        <input
                          type="text"
                          className="form-control"
                          id="text"
                          placeholder="तहसील का नाम"
                          value={tehsil}
                          onChange={(e) => settehsil(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="mobile">जिला:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="mobile"
                          value={district}
                          placeholder="जिला का नाम "
                          onChange={(e) => setdistrict(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* <br /> */}
                    <div className="col-md-6 row">
                      <label htmlFor="whom">ग्रुप आईडी :</label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        id="ग्रुप"
                        placeholder="ग्रुप आईडी"
                        value={centerId}
                        readOnly
                        onChange={(e) => setcenterId(e.target.value)}
                      />
                    </div>
                    {/* <br /> */}
                    <div className="row">
                      {auth?.user?.role == "storeManager" ? (
                        <>
                          <div className="col-md-3">
                            <label htmlFor="role">स्टाफ का नाम:</label>
                            <Select
                              options={employeeList}
                              searchBy="username"
                              labelField="username"
                              valueField="username"
                              multi={false}
                              loading={false}
                              handle={true}
                              addPlaceholder="Search"
                              color="#007bff"
                              clearable={false}
                              keepSelectedInList={true}
                              closeOnSelect={true}
                              dropdownPosition="bottom"
                              direction="ltr"
                              dropdownHeight="1000px"
                              onChange={(values) => {
                                setstaffName(values[0]?.username);
                                setstaffId(values[0]?.staffId);
                              }}
                            />
                          </div>

                          <div className="col-md-3">
                            <label htmlFor="name">स्टाफ आईडी:</label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              id="स्टाफ"
                              placeholder="स्टाफ आईडी"
                              readOnly
                              value={staffId}
                              onChange={(e) => setstaffId(e.target.value)}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="col-md-3">
                        <label htmlFor="name">Date Of Collection :</label>
                        <DatePicker
                          dateFormat="DD/MM/YYYY"
                          showClearButton={false}
                          id="to-date"
                          value={paymentDate}
                          onChange={(e) => setpaymentDate(e)}
                          placeholder="दिनांक"
                          //   maxDate={new Date().toISOString()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <center>
                  <div className="card-footer">
                    <input
                      style={{
                        backgroundColor: "#00a65a",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Save"
                      name="add_save"
                      disabled={isSubmit}
                      onClick={submitForm}
                    />
                    <input
                      style={{
                        backgroundColor: "#f39c12",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Close"
                      name="add_save"
                      onClick={handleClose}
                    />
                  </div>
                  <StatusToast />
                </center>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddCenter;
