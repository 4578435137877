import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useState } from "react";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";

const OnBoardFormView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state;
  console.log(data);
  const { auth } = useAuth();

  /**
   * Code For Go Reverce Page
   */

  const handleGoBack = () => {
    window.history.back();
  };

  /**
   * End
   */
  const DateForamate = (inputDateString) => {
    if (!inputDateString) {
      return "";
    }
    const parts = inputDateString.split("T")[0].split("-"); // Split and extract date parts
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  var targetRef = useRef(null);

  const handlePrint = () => {
    const targetElement = targetRef.current;

    if (targetElement) {
      window.print();
    }
  };

  return (
    <>
      {/* Header Wrapper Start */}
      <div className="container" id="section-to-print" ref={targetRef}>
        <div
          className="loan-application-main-wrapper"
          style={{
            width: "100%",
            margin: "0 auto",
            border: "1px solid #111",
            padding: "0px 11px",
            // backgroundColor: "#bbdbeb",
            backgroundColor: "white",
          }}
        >
          <div className="">
            <div className="row">
              <div
                className="col-lg-12 col-md-12 col-12 mar pad"
                style={{ padding: 0, margin: 0 }}
              >
                <div
                  className="invoice-title"
                  style={{
                    width: "100%",
                    float: "left",
                    textAlign: "center" /* borderBottom: '1px solid #111', */,
                    paddingBottom: 2,
                  }}
                >
                  <p
                    className="title-heading"
                    style={{
                      fontFamily: '"Roboto", sans-serif',
                      lineHeight: "2.1",
                      color: "#222222",
                      margin: 0,
                      padding: 0,
                      textAlign: "center",
                      fontWeight: 600,
                      textTransform: "uppercase",
                      fontSize: 45,
                    }}
                  >
                    केयर इंटरप्राइजेज
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "#111",
                      fontWeight: 800 /* lineHeight: 15, */,
                      fontSize: 17,
                    }}
                  >
                    रजि. नं. C/7551701
                  </p>
                  <h1
                    className="title-heading"
                    style={{
                      fontFamily: '"Roboto", sans-serif',
                      lineHeight: "1.1",
                      color: "#222222",
                      margin: 0,
                      padding: 0,
                      textAlign: "center",
                      fontWeight: 600,
                      textTransform: "uppercase",
                      fontSize: 25,
                    }}
                  >
                    सद्स्य का विवरण एवं आवास सर्वेक्षण प्रपत्र
                  </h1>
                </div>
                {/*  */}
                <div
                  className="di-invoice-main-wrapper"
                  style={{ width: "100%", float: "left" }}
                >
                  <br />
                  <br />
                  <div className="form-group">
                    <h3
                      className="card-header"
                      style={{ fontFamily: "monospace", fontWeight: "bolder" }}
                    >
                      सेंटर का विवरण
                    </h3>
                    <div className="row" id={2}>
                      <div className="col-md-5">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          सेंटर का नाम :
                        </label>
                        {data?.center}
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          तहसील का नाम :
                        </label>
                        {data?.tehsil}
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          जिला का नाम :
                        </label>
                        {data?.district}
                      </div>
                    </div>
                    <div className="row" id={2}>
                      <div className="col-md-4">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          सेंटर आईडी :
                        </label>
                        {data?.centerId}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-5">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          स्टाफ का नाम :
                        </label>
                        {data?.staffName}
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          स्टाफ आईडी :
                        </label>
                        {data?.staffId}
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          दिनांक :
                        </label>
                        {DateForamate(data?.date)}
                      </div>
                    </div>
                    <h3
                      className="card-header"
                      style={{ fontFamily: "monospace", fontWeight: "bolder" }}
                    >
                      सदस्य का विवरण
                    </h3>
                    <div className="row">
                      <div className="col-md-5">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          सदस्य का नाम :
                        </label>
                        {data?.customer}
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          आयु :
                        </label>
                        {data?.age}
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          मोबाईल :
                        </label>
                        {data?.mobile}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-5">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          पिता / पति / पुत्र का नाम :
                        </label>
                        {data?.parent}
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          आयु :
                        </label>
                        {data?.parentAge}
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          मोबाईल :
                        </label>
                        {data?.parentMobile}
                      </div>
                    </div>
                    <div className="row" id={2}>
                      <div className="col-md-5">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          घर का पता :
                        </label>
                        {data?.gharkaavlokan}
                      </div>
                      <div className="col-md-3">
                        <label
                          style={{
                            marginRight: "0px",
                            padding: "5px",
                          }}
                        >
                          <input
                            type="checkbox"
                            value="निजी"
                            checked={data?.selectedGharMode === "निजी"}
                            readOnly
                          />
                          निजी{" "}
                        </label>
                      </div>
                      <div className="col-md-3">
                        <label
                          style={{
                            marginRight: "0px",
                            padding: "5px",
                          }}
                        >
                          <input
                            type="checkbox"
                            value="किरायेदार"
                            checked={data?.selectedGharMode === "किरायेदार"}
                            readOnly
                          />
                          किरायेदार{" "}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-5">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          परिवार की दैनिक आय :
                        </label>
                        Rs. {data?.familyDailyIncome}
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          सप्ताहिक आय :
                        </label>
                        Rs. {data?.weeklyIncome}
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          मासिक आय :
                        </label>
                        Rs. {data?.monthlyIncome}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-5">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          कितनी कंपनी से ऋण लिया :
                        </label>
                        {data?.otherCompaniesLoan}
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          ऋण की राशि :
                        </label>
                        Rs. {data?.loanAmount}
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Roboto", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            textTransform: "uppercase",
                            fontSize: 17,
                          }}
                        >
                          बकाया ऋण :
                        </label>
                        Rs. {data?.dueAmount}
                      </div>
                    </div>
                    <br />
                    <h2
                      className="card-header"
                      style={{
                        fontFamily: '"Roboto", sans-serif',
                        lineHeight: "2.1",
                        color: "#222222",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        fontWeight: 600,
                        textTransform: "uppercase",
                        fontSize: 30,
                      }}
                    >
                      प्रोडक्ट के लिए आवेदन-प्रपत्र
                    </h2>
                    <p
                      style={{
                        margin: 5,
                        padding: 5,
                        color: "#111",
                        fontWeight: 800 /* lineHeight: 15, */,
                        fontSize: 18,
                      }}
                    >
                      में {"   " + data?.customer + "  "} इस बात की पुष्टि
                      करता/करती हूं की मैने केयर इंटरप्राइजेस द्वारा आयोजित
                      अनिवार्य सामूहिक प्रशिक्षण (CGT) ले ली हैं, जिसमे मैने
                      प्रक्रियाओं और प्रणालियों को समझ लिया हैं और मुझे सभी नियम
                      व शर्ते&nbsp;स्वीकार&nbsp;है |
                      <br />
                      <br />
                      अतः में अपनी व अपने परिवार की स्वेच्छा व सहमति से इस
                      प्रोडक्ट के लिए आवेदन प्रस्तुत कर रहा/रही हूं ।
                      <br />
                      <br />
                      <table
                        id="invoice-item-table"
                        className="table table-bordered"
                      >
                        <tbody>
                          <tr>
                            <th width="5%">क्रमांक संख्या.</th>
                            <th width="45%">प्रोडक्ट का नाम</th>
                            <th width="10%">प्रोडक्ट की मात्रा</th>
                            <th width="15%">प्रोडक्ट का मूल्य</th>
                            <th width="15%">कुल राशि</th>
                          </tr>
                          {data?.data.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <span id="sr_no">{index + 1}</span>
                              </td>
                              <td>{item?.productName}</td>
                              <td>{item?.productQuantity}</td>
                              <td>{item?.productAmount}</td>
                              <td>{item?.totalAmount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <br />
                      कुल भुगतान: Rs. {data?.totalAmount}
                              अग्रिम भुगतान : Rs.{data?.downPayment}        
                      प्रोसेसिंग शुल्क : : Rs.{data?.proccesingFee}
                      <br />
                      कुल अग्रिम भुगतान : Rs.{data?.totalAddaygiRakam}
                           अदायगी रकम : Rs.{data?.addaygiRakam}         कुल
                      किस्त माह : {data?.totalMonthluNumber}
                      <br />
                      माह किस्त : Rs. {data?.monthlyEMI}
                      <br />
                      <br />
                      इस प्रोडक्ट के आवेदन के लिए मेने जो भी जानकारी केयर
                      इंटरप्राइजेस के स्टाफ को दी है, वह पूर्णतः सत्य हैं तथा जब
                      तक प्रोडक्ट की किश्ते पुरी तरह पूरी नहीं हो जाती, तब तक
                      में प्रोडक्ट को न तो बेच सकता/सकती हूं , न ही गिरवी रख
                      सकती हूं।
                      <br />
                      <br />
                      इन सब नियमो व शर्तो को भली—भांति समझ कर में यह आवेदन
                      प्रस्तुत कर रही हूं ।
                      <br />
                      <br />
                      <br />
                      आवेदन कर्ता के हस्ताक्षर         पिता/पति/पुत्र के
                      हस्ताक्षर       सेंटर लीडर के हस्ताक्षर       सेंटर मैनेजर
                      के हस्ताक्षर
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <center>
        <button
          className="btn-convert"
          style={{
            outline: "none",
            backgroundColor: "#f44336",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "16px",
            margin: "4px 2px",
            cursor: "pointer",
          }}
          onClick={handlePrint}
        >
          Print On-Board-Form
        </button>
      </center>
      <br />
      <center>
        <button
          className="btn-convert"
          style={{
            outline: "none",
            backgroundColor: "#f44336",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "16px",
            margin: "4px 2px",
            cursor: "pointer",
          }}
          onClick={handleGoBack}
        >
          Go Back
        </button>
      </center>
      <br />
    </>
  );
};

export default OnBoardFormView;
