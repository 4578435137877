import React, { useEffect } from "react";
import Header from "../components/header/Header";
import { useState } from "react";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import { useLocation } from "react-router-dom";
import { DatePicker } from "reactstrap-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-dropdown-select";
import swal from "sweetalert";

const createInvoice = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const location = useLocation();
  const data = location?.state;
  console.log(data);
  const [customerList, setCustomerList] = useState([]);
  const [productList, setproductList] = useState([]);
  const [customer, setCustomer] = useState(data?.customer || "");
  const [note, setnote] = useState(data?.note || "");

  // Logic show additional form
  const [selectedOption, setSelectedOption] = useState("Cash");
  const [downPayment, setdownPayment] = useState(0);
  const [monthDuration, setmonthDuration] = useState(0);

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  var s;
  if (data?.salesDate) {
    s = new Date(data?.salesDate);
  } else {
    s = new Date();
  }

  if (data) {
    var response;
    const itemlists = async () => {
      response = await axios.get("/product/list", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
    };
    itemlists();
    const itemList = response?.data?.list;

    itemList?.map((item, index) => {
      const updatedField = {
        sr_no: index + 1,
        item_name: item?.itemName,
        item_quantity: item?.quantity,
        item_price: item?.rate,
        item_actual_amount: "",
        item_tax1_rate: item?.taxRate,
        item_tax1_amount: "",
        item_final_amount: "",
      };

      setFields((prevFields) => {
        const updatedFields = [...prevFields];
        updatedFields[index] = updatedField;
        return updatedFields;
      });
    });
  }

  const [salesDate, setSalesDate] = useState(s);

  useEffect(() => {
    const customerListt = async () => {
      try {
        const response = await axios.get("/customer/list", {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setCustomerList(response.data.list);
        //console.log(response.data.list);
      } catch (err) {
        console.log(err);
      }
    };
    const itemLists = async () => {
      try {
        const response = await axios.get("/product/list", {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setproductList(response.data.list);
        //console.log(response.data.list);
      } catch (err) {
        console.log(err);
      }
    };

    customerListt();
    itemLists();
  }, []);

  const submitForm = async () => {
    if (!customer || !salesDate) {
      return toast.error("Please Fill Customer Name & Date", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    var count = 0;
    fields.map((item, index) => {
      const { item_name, item_quantity, item_price, item_tax1_rate } = item;
      if (!item_name || !item_quantity || !item_price) {
        count++;
        return toast.error("Please Fill All Item Fields", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });

    if (selectedOption === "EMI" && (!downPayment || !monthDuration)) {
      return swal(
        "You Selected EMI!",
        "Please Fill Down Payment & Month Duration",
        "error"
      );
    }

    if (count == 0) {
      const willDelete = await swal({
        title: "Are you sure?",
        text: "Once you created this invoice, you will not be able to update this invoice!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
      if (!willDelete) return;
      try {
        const url = data?._id ? "/invoice/update" + id : "/invoice/add";

        const response = await axios.post(
          url,
          {
            customer: customer,
            date: salesDate,
            discount: discount,
            other: charges,
            total: grandTotal,
            quantity: itemQuantity,
            note: note,
            paymentMode: selectedOption,
            downPayment,
            monthDuration,
            data: fields,
          },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        if (response.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/invoice/list");
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  /**
   * Code For Create Input fields Dynamically
   */

  const [fields, setFields] = useState([
    {
      sr_no: 1,
      item_name: "",
      item_quantity: 1,
      item_price: 0,
      item_actual_amount: "",
      item_tax1_rate: "",
      item_tax1_amount: "",
      item_final_amount: "",
    },
  ]);
  const addInputField = () => {
    const sr_no = fields.length + 1;
    const newField = {
      sr_no,
      item_name: "",
      item_quantity: 1,
      item_price: 0,
      item_actual_amount: "",
      item_tax1_rate: "",
      item_tax1_amount: "",
      item_final_amount: "",
    };
    GrandTotal();
    setFields((prevFields) => [...prevFields, newField]);
  };

  const removeInputField = (index) => {
    setFields((prevFields) => prevFields.filter((_, i) => i !== index));
  };
  const [charges, setCharges] = useState(data?.other || 0);
  const [discount, setDiscount] = useState(data?.discount || 0);
  const [grandTotal, setGrandTotal] = useState(data?.total || 0);
  const [subTotal, setSubTotal] = useState(0);
  const [itemQuantity, setitemQuantity] = useState(data?.quantity || 0);

  const GrandTotal = () => {
    var total = 0;
    var qua = 0;
    fields.map((item, index) => {
      console.log(+item?.item_quantity + 10);
      qua = +item?.item_quantity + qua;
      total =
        total +
        item?.item_quantity * item?.item_price +
        item?.item_quantity * item?.item_price * (item?.item_tax1_rate / 100);
    });
    setSubTotal(total);
    setitemQuantity(qua);
    setGrandTotal(total - discount + parseInt(charges));
  };

  const updateFieldValue = (index, field, value) => {
    setFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][field] = value;
      return updatedFields;
    });
    GrandTotal();
  };
  /**End The Logic */

  useEffect(() => {
    if (data?.salesID) {
      const fetchInvoiceItem = async () => {
        console.log("KAPIL");
        try {
          const response = await axios.get(
            `/sales/fetch-invoice-item/${data.salesID}`,
            {
              headers: {
                Authorization: `Bearer ${auth.token}`,
              },
            }
          );

          const itemList = response?.data?.list;
          console.log(itemList);
          itemList?.map((item, index) => {
            const updatedField = {
              sr_no: index + 1,
              item_name: item?.itemName,
              item_quantity: item?.quantity,
              item_price: item?.rate,
              item_actual_amount: "",
              item_tax1_rate: item?.taxRate,
              item_tax1_amount: "",
              item_final_amount: "",
            };

            setFields((prevFields) => {
              const updatedFields = [...prevFields];
              updatedFields[index] = updatedField;
              return updatedFields;
            });
          });
        } catch (err) {
          console.log(err);
        }
      };
      fetchInvoiceItem();
    }
  }, [data?.salesID, auth.token]);

  const buttonStyle = {
    position: "absolute",
    top: "234px",
    right: "0px",
    zIndex: "2",
    borderRadius: "5px",
    border: "none",
    height: "37px",
    cursor: "pointer",
    color: "white",
    backgroundColor: "#1e90ff",
    transform: "translateX(2px)",
  };

  return (
    <>
      <Header page="Add Sale" />
      <div className="card card-default">
        <form
          method="post"
          id="invoice_form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
          style={{ marginLeft: 15, marginRight: 15, marginTop: 15 }}
        >
          <div className="table-responsive" data-select2-id={53}>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label>Customer Name</label>
                  <label className="float-right" title="New Customer?">
                    <Link to="/customer/add">+Add</Link>
                  </label>
                  <Select
                    options={customerList}
                    searchBy="customerName"
                    labelField="customerName"
                    valueField="customerName"
                    multi={false}
                    loading={false}
                    handle={true}
                    addPlaceholder="Search"
                    color="#007bff"
                    clearable={false}
                    keepSelectedInList={true}
                    closeOnSelect={true}
                    dropdownPosition="bottom"
                    direction="ltr"
                    dropdownHeight="1000px"
                    onChange={(values) => {
                      setCustomer(values[0]);
                    }}
                  />
                  <span
                    className="select2 select2-container select2-container--default select2-container--below"
                    dir="ltr"
                    data-select2-id={1}
                    style={{ width: "100%" }}
                  >
                    <span className="dropdown-wrapper" aria-hidden="true" />
                  </span>
                  {customer?.customerName ? (
                    <div className="row" style={{ display: "flex" }}>
                      <div className="col-md-12">
                        <br />
                        <span id="claddress">{customer.address}</span>
                        <br />
                        <span id="clphone">{customer.mobile}</span>
                        <span id="clemail">{customer.email}</span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <label>Invoice Date</label>
                <br></br>
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  showClearButton={false}
                  id="to-date"
                  value={salesDate}
                  onChange={(e) => setSalesDate(e)}
                  placeholder="To Date"
                  maxDate={new Date().toISOString()}
                />
                <br></br>
              </div>
            </div>
            <br />
            <table id="invoice-item-table" className="table table-bordered">
              <tbody>
                <tr>
                  <th width="4%">Sr No.</th>
                  <th width="18%">Item Name</th>
                  <th width="9%">Quantity</th>
                  <th width="9%">Rate</th>
                  <th width="10%">Total Amt.</th>
                  <th width="18%" colSpan={2}>
                    Tax %
                  </th>
                  <th width="12%" rowSpan={2}>
                    Total
                  </th>
                  <th width="3%" rowSpan={2} />
                </tr>
                <tr>
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                  <th width="5%">Rate</th>
                  <th width="10%">Amt.</th>
                </tr>
                {fields.map((field, index) => (
                  <tr key={index}>
                    <td>
                      <span id="sr_no">{field.sr_no}</span>
                    </td>
                    <td>
                      <Select
                        options={productList}
                        searchBy="productName"
                        labelField="productName"
                        valueField="productName"
                        multi={false}
                        loading={false}
                        handle={true}
                        addPlaceholder="Search"
                        color="#007bff"
                        clearable={false}
                        keepSelectedInList={true}
                        closeOnSelect={true}
                        dropdownPosition="bottom"
                        direction="ltr"
                        dropdownHeight="1000px"
                        onChange={(values) => {
                          updateFieldValue(index, "item_name", values[0]);
                          updateFieldValue(
                            index,
                            "item_price",
                            values[0]?.price
                          );
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="item_quantity[]"
                        id={`item_quantity${field.sr_no}`}
                        data-srno={field.sr_no}
                        className="form-control input-sm item_quantity"
                        value={field.item_quantity}
                        onChange={(e) =>
                          updateFieldValue(
                            index,
                            "item_quantity",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="item_price[]"
                        id={`item_price${field.sr_no}`}
                        data-srno={field.sr_no}
                        className="form-control input-sm number_only item_price"
                        value={field.item_price}
                        readOnly
                        onChange={(e) =>
                          updateFieldValue(index, "item_price", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="item_actual_amount[]"
                        id={`item_actual_amount${field.sr_no}`}
                        data-srno={field.sr_no}
                        className="form-control input-sm item_actual_amount"
                        readOnly
                        value={field.item_quantity * field.item_price}
                        // value={field.item_actual_amount}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="item_tax1_rate[]"
                        id={`item_tax1_rate${field.sr_no}`}
                        data-srno={field.sr_no}
                        className="form-control input-sm number_only item_tax1_rate"
                        value={field.item_tax1_rate}
                        onChange={(e) =>
                          updateFieldValue(
                            index,
                            "item_tax1_rate",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="item_tax1_amount[]"
                        id={`item_tax1_amount${field.sr_no}`}
                        data-srno={field.sr_no}
                        readOnly
                        className="form-control input-sm item_tax1_amount"
                        // value={field.item_tax1_amount}
                        value={Math.round(
                          field.item_quantity *
                            field.item_price *
                            (field.item_tax1_rate / 100)
                        )}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="item_final_amount[]"
                        id={`item_final_amount${field.sr_no}`}
                        data-srno={field.sr_no}
                        readOnly
                        className="form-control input-sm item_final_amount"
                        // value={field.item_final_amount}
                        value={Math.round(
                          field.item_quantity * field.item_price +
                            field.item_quantity *
                              field.item_price *
                              (field.item_tax1_rate / 100)
                        )}
                      />
                    </td>
                    {fields[index]?.sr_no == 1 ? (
                      <td>
                        <button
                          type="button"
                          name="add_row"
                          id="add_row"
                          className="btn btn-success btn-xs"
                          onClick={addInputField}
                        >
                          +
                        </button>
                      </td>
                    ) : (
                      ""
                    )}
                    {fields[index]?.sr_no != 1 ? (
                      <td>
                        <button
                          type="button"
                          name="add_row"
                          id="add_row"
                          className="btn btn-danger btn-xs remove_row"
                          onClick={() => removeInputField(index)}
                        >
                          X
                        </button>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="row">
              <div className="col-md-3">
                <label htmlFor="" className="col-sm-4 control-label">
                  Quantity :-
                </label>
                <label
                  className="control-label total_quantity text-success"
                  style={{ fontSize: "15pt" }}
                >
                  {itemQuantity}.00
                </label>
                <br />
                Discount-:
                <input
                  type="text"
                  value={discount}
                  onClick={GrandTotal}
                  onChange={(e) => {
                    setDiscount(e.target.value);
                  }}
                  name="discount"
                  id="discount"
                  className="form-control input-sm discount"
                  placeholder="Discount"
                  required=""
                />
                <br />
                Other Charges-:
                <input
                  type="text"
                  name="othr"
                  id="othr"
                  className="form-control input-sm othr"
                  placeholder="Other Charges"
                  // defaultValue={0}
                  value={charges}
                  onClick={GrandTotal}
                  onChange={(e) => {
                    setCharges(e.target.value);
                  }}
                  required=""
                />
                <br />
                Note-:
                <textarea
                  type="text"
                  name="note"
                  id="note"
                  className="form-control input-sm note"
                  placeholder="Write Something"
                  // defaultValue={0}
                  value={note}
                  onChange={(e) => {
                    setnote(e.target.value);
                  }}
                  required=""
                />
                <br />
                <div className="">
                  <h4>Payment Type</h4>
                  <label
                    style={{
                      marginRight: "30px",
                      cursor: "pointer",
                      padding: "5px",
                    }}
                  >
                    <input
                      type="radio"
                      value="Cash"
                      checked={selectedOption === "Cash"}
                      onChange={handleRadioChange}
                    />
                    Cash{" "}
                  </label>
                  <label style={{ marginRight: "20px", cursor: "pointer" }}>
                    <input
                      type="radio"
                      value="EMI"
                      checked={selectedOption === "EMI"}
                      onChange={handleRadioChange}
                    />
                    EMI
                  </label>
                </div>
                <br />
                {selectedOption === "EMI" ? (
                  <>
                    Down Payment-:
                    <input
                      type="text"
                      value={downPayment}
                      // onClick={GrandTotal}
                      onChange={(e) => {
                        setdownPayment(e.target.value);
                      }}
                      name="downPayment"
                      id="downPayment"
                      className="form-control input-sm downPayment"
                      placeholder="Down Payment"
                      required=""
                    />
                    <br />
                    Month Duration-:
                    <input
                      type="text"
                      name="othr"
                      id="othr"
                      className="form-control input-sm othr"
                      placeholder="Month Duration"
                      value={monthDuration}
                      // onClick={GrandTotal}
                      onChange={(e) => {
                        setmonthDuration(e.target.value);
                      }}
                      required=""
                    />
                    <br />
                    Amount After Down Payment-:
                    <input
                      type="text"
                      name="othr"
                      id="othr"
                      className="form-control input-sm othr"
                      placeholder="Month Duration"
                      value={Math.round(grandTotal) - downPayment}
                      readOnly
                      required=""
                    />
                    <br />
                    EMI Per Month-:
                    <input
                      type="text"
                      name="othr"
                      id="othr"
                      className="form-control input-sm othr"
                      placeholder="Month Duration"
                      value={Math.round(
                        (Math.round(grandTotal) - downPayment) / monthDuration
                      )}
                      readOnly
                      required=""
                    />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </div>
              <table className="col-md-7">
                <tbody>
                  <tr>
                    <th className="text-right" style={{ fontSize: 17 }}>
                      Subtotal
                    </th>
                    <th
                      className="text-right"
                      style={{ paddingLeft: "10%", fontSize: 17 }}
                    >
                      <h4>
                        <b id="subtotal_amt" name="subtotal_amt">
                          {Math.round(subTotal) + ".00" || "0.00"}
                        </b>
                      </h4>
                    </th>
                  </tr>
                  <tr>
                    <th className="text-right" style={{ fontSize: 17 }}>
                      Other Charges
                    </th>
                    <th
                      className="text-right"
                      style={{ paddingLeft: "10%", fontSize: 17 }}
                    >
                      <h4>
                        <b id="other_charges_amt" name="other_charges_amt">
                          {charges || "0.00"}
                        </b>
                      </h4>
                    </th>
                  </tr>
                  <tr>
                    <th className="text-right" style={{ fontSize: 17 }}>
                      Coupon Discount
                    </th>
                    <th
                      className="text-right"
                      style={{ paddingLeft: "10%", fontSize: 17 }}
                    >
                      <h4>
                        <b id="coupon_discount_amt" name="coupon_discount_amt">
                          0.00
                        </b>
                      </h4>
                    </th>
                  </tr>
                  <tr>
                    <th className="text-right" style={{ fontSize: 17 }}>
                      Discount on All
                    </th>
                    <th
                      className="text-right"
                      style={{ paddingLeft: "10%", fontSize: 17 }}
                    >
                      <h4>
                        <b id="discount_to_all_amt" name="discount_to_all_amt">
                          {discount || "0.00"}
                        </b>
                      </h4>
                    </th>
                  </tr>
                  <tr style={{}}>
                    <th className="text-right" style={{ fontSize: 17 }}>
                      {" "}
                      <i
                        className="hover-q "
                        data-container="body"
                        data-toggle="popover"
                        data-placement="top"
                        data-content="Go to Site Settings-> Site -> Disable the Round Off(Checkbox)."
                        data-html="true"
                        data-trigger="hover"
                        data-original-title="Do you wants to Disable Round Off ?"
                        title=""
                      >
                        <i className="fa fa-info-circle text-maroon text-black hover-q" />
                      </i>
                    </th>
                    <th
                      className="text-right"
                      style={{ paddingLeft: "10%", fontSize: 17 }}
                    >
                      <h4>
                        <b id="round_off_amt" name="tot_round_off_amt">
                          0.00
                        </b>
                      </h4>
                    </th>
                  </tr>
                  <tr>
                    <th className="text-right" style={{ fontSize: 17 }}>
                      Grand Total
                    </th>
                    <th
                      className="text-right"
                      style={{ paddingLeft: "10%", fontSize: 17 }}
                    >
                      <h4>
                        <b id="total_amt" name="total_amt">
                          {Math.round(grandTotal) + ".00" || "0.00"}
                        </b>
                      </h4>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <b>
            <center>
              <div className="card-footer ">
                <input
                  style={{
                    backgroundColor: "#00a65a",
                    color: "white",
                    width: "15%",
                    height: "38px",
                    border: "none",
                    marginRight: "40px",
                  }}
                  type="button"
                  value="Create"
                  name="add_save"
                  onClick={() => {
                    submitForm();
                  }}
                />
                <input
                  style={{
                    backgroundColor: "#f39c12",
                    color: "white",
                    width: "15%",
                    height: "38px",
                    border: "none",
                    marginRight: "40px",
                  }}
                  type="button"
                  value="Close"
                  name="add_save"
                  onClick={() => {
                    navigate("/");
                  }}
                />
              </div>
              <StatusToast />
            </center>
            <br />
          </b>
        </form>
        <StatusToast />
      </div>
    </>
  );
};

export default createInvoice;
