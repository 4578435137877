import { useRef, useState } from "react";
import Header from "../components/header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import axios, { CancelToken } from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useEffect } from "react";
import Select from "react-dropdown-select";
import { DatePicker } from "reactstrap-date-picker";
import "react-datepicker/dist/react-datepicker.css";

const CollectionSheet = () => {
  const location = useLocation();
  const data = location.state;
  // sadasy ka vivaran and housing sarveshan form
  const [center, setcenter] = useState(data?.center || "");
  const [district, setdistrict] = useState(data?.district || "");
  const [centerId, setcenterId] = useState(data?.centerId || "");
  const [staffName, setstaffName] = useState(data?.staffName || "");
  const [staffId, setstaffId] = useState(data?.staffId || "");
  const [date, setdate] = useState(new Date());
  const [disbursementDate, setdisbursementDate] = useState(
    data?.disbursementDate || ""
  );

  const [totalInstallment, settotalInstallment] = useState(
    data?.totalInstallment || 0
  );

  const navigate = useNavigate();
  const [isSubmit, setisSubmit] = useState(false);
  const { auth } = useAuth();

  // radio button

  const handleRadioChange = (event) => {
    setselectedGharMode(event.target.value);
  };

  const [customerList, setCustomerList] = useState([]);
  const [productList, setproductList] = useState([]);

  useEffect(() => {
    const customerListt = async () => {
      try {
        const response = await axios.get("/customer/list", {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setCustomerList(response.data.list);
        //console.log(response.data.list);
      } catch (err) {
        console.log(err);
      }
    };
    const itemLists = async () => {
      try {
        const response = await axios.get("/product/list", {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setproductList(response.data.list);
        //console.log(response.data.list);
      } catch (err) {
        console.log(err);
      }
    };

    customerListt();
    itemLists();
  }, []);

  // To insert data in table due to edit

  useEffect(() => {
    try {
      if (data) {
        let itemList = data?.data;
        console.log(data);
        itemList?.map((item, index) => {
          const updatedField = {
            sr_no: 1,
            customerName: item?.customerName,
            productName: item?.productName,
            productAmount: item?.productAmount,
            depositAmount: item?.depositAmount,
            installment: item?.installment,
            advance: item?.advance,
            balance: item?.balance,
          };

          setFields((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index] = updatedField;
            return updatedFields;
          });
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const GrandTotal = () => {
    var total = 0;
    fields.map((item, index) => {
      total = total + +item?.installment;
    });
    settotalInstallment(total);
  };

  const [fields, setFields] = useState([
    {
      sr_no: 1,
      customerName: "",
      productName: "",
      productAmount: 0,
      depositAmount: 0,
      installment: 0,
      advance: 0,
      balance: 0,
    },
  ]);

  const addInputField = () => {
    const sr_no = fields.length + 1;
    const newField = {
      sr_no,
      customerName: "",
      productName: "",
      productAmount: 0,
      depositAmount: 0,
      installment: 0,
      advance: 0,
      balance: 0,
    };
    setFields((prevFields) => [...prevFields, newField]);
  };

  const removeInputField = (index) => {
    setFields((prevFields) => prevFields.filter((_, i) => i !== index));
  };

  const updateFieldValue = (index, field, value) => {
    setFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][field] = value;
      return updatedFields;
    });
    GrandTotal();
  };
  console.log(fields);
  const handleClose = async () => {
    navigate("/");
  };

  const Submit = async () => {
    if (!center || !centerId || !staffName || !staffId || !date) {
      return swal({
        title: "चेतावनी!",
        text: `
        कृपया सेंटर का सारा विवरण भरें`,
        icon: "warning",
      });
    }

    var count = 0;
    fields.map((item) => {
      const {
        customerName,
        productName,
        productAmount,
        depositAmount,
        installment,
        advance,
        balance,
      } = item;
      if (
        !customerName ||
        !productName ||
        !productAmount ||
        !depositAmount ||
        !installment
      ) {
        count++;
        return swal({
          title: "चेतावनी!",
          text: `कृपया सदस्य & प्रोडक्ट का सारा विवरण भरें`,
          icon: "warning",
        });
      }
    });

    if (isSubmit) return;
    setisSubmit(true);

    try {
      const id = data?._id;
      const url = data?._id
        ? "/collection-sheet/" + id
        : "/collection-sheet/add";
      const response = await axios.post(
        url,
        {
          center,
          date,
          centerId,
          staffName,
          staffId,
          disbursementDate,
          totalInstallment,
          data: fields,
        },
        {
          headers: { authorization: "bearer " + auth.token },
        }
      );
      console.log(response);
      if (response?.data?.success) {
        swal({
          title: "Success!",
          text: `${response.data.message}`,
          icon: "success",
        });

        navigate("/collection/sheet/list");
      } else {
        swal({
          title: "Failure!",
          text: `${response.data.message}`,
          icon: "error",
        });
      }
    } catch (err) {
      swal({ title: "Error!", text: `${err.message}`, icon: "error" });
    } finally {
      setisSubmit(false);
    }
  };

  return (
    <>
      <Header page="Collection Sheet" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">संग्रह पत्रक</h3>
              </div>
              <form className="needs-validation" noValidate>
                <div className="card-body">
                  <div className="form-group">
                    <h3
                      className="card-header"
                      style={{
                        fontFamily: "monospace",
                        fontWeight: "bolder",
                      }}
                    >
                      सेंटर का विवरण
                    </h3>
                    <br />
                    <div className="row" id="2">
                      <div className="col-md-3">
                        <label htmlFor="name">सेंटर का नाम :</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="सेंटर का नाम"
                          value={center}
                          onChange={(e) => setcenter(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="name">वर्तमान दिनांक :</label>
                        <DatePicker
                          dateFormat="DD/MM/YYYY"
                          showClearButton={false}
                          id="to-date"
                          value={date}
                          onChange={(e) => setdate(e)}
                          placeholder="दिनांक"
                          maxDate={new Date().toISOString()}
                        />
                      </div>
                    </div>
                    {/* <br /> */}
                    <div className="col-md-6 row">
                      <label htmlFor="whom">सेंटर आईडी :</label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        id="सेंटर"
                        placeholder="सेंटर आईडी"
                        value={centerId}
                        onChange={(e) => setcenterId(e.target.value)}
                      />
                    </div>
                    {/* <br /> */}
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="whom">स्टाफ का नाम:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="स्टाफ"
                          placeholder="स्टाफ का नाम"
                          value={staffName}
                          onChange={(e) => setstaffName(e.target.value)}
                        />
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="name">स्टाफ आईडी:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="स्टाफ"
                          placeholder="स्टाफ आईडी"
                          value={staffId}
                          onChange={(e) => setstaffId(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="name">भुगतान दिनांक :</label>
                        <DatePicker
                          dateFormat="DD/MM/YYYY"
                          showClearButton={false}
                          id="to-date"
                          value={disbursementDate}
                          onChange={(e) => setdisbursementDate(e)}
                          placeholder="दिनांक"
                          // maxDate={new Date().toISOString()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <h3
                      className="card-header"
                      style={{
                        fontFamily: "monospace",
                        fontWeight: "bolder",
                      }}
                    >
                      सदस्य & प्रोडक्ट का विवरण सूची
                    </h3>
                    <br />
                    <table
                      id="invoice-item-table"
                      className="table table-bordered"
                    >
                      <tbody>
                        <tr>
                          <th width="1%">क्रमांक संख्या.</th>
                          <th width="12%">सदस्य का नाम</th>
                          <th width="15%">प्रोडक्ट का नाम</th>
                          <th width="9%">प्रोडक्ट का मूल्य</th>
                          <th width="9%">जमा राशि</th>
                          <th width="7%">किश्त</th>
                          <th width="18%" colSpan={2}>
                            var
                          </th>
                          <th width="3%" rowSpan={2} />
                        </tr>
                        <tr>
                          <th />
                          <th />
                          <th />
                          <th />
                          <th />
                          <th />
                          <th width="7%">अग्रिम राशि</th>
                          <th width="7%">बैलेंस</th>
                        </tr>
                        {fields.map((field, index) => (
                          <tr key={index}>
                            <td>
                              <span id="sr_no">{field.sr_no}</span>
                            </td>
                            <td>
                              <Select
                                options={customerList}
                                searchBy="customerName"
                                labelField="customerName"
                                valueField="customerName"
                                multi={false}
                                name="KAPIL"
                                loading={false}
                                handle={true}
                                addPlaceholder="Search"
                                color="#007bff"
                                clearable={false}
                                keepSelectedInList={true}
                                closeOnSelect={true}
                                dropdownPosition="bottom"
                                direction="ltr"
                                dropdownHeight="1000px"
                                onChange={(values) => {
                                  updateFieldValue(
                                    index,
                                    "customerName",
                                    values[0]
                                  );
                                }}
                              />
                            </td>
                            <td>
                              <Select
                                options={productList}
                                searchBy="productName"
                                labelField="productName"
                                valueField="productName"
                                multi={false}
                                loading={false}
                                handle={true}
                                addPlaceholder="Search"
                                color="#007bff"
                                clearable={false}
                                keepSelectedInList={true}
                                closeOnSelect={true}
                                dropdownPosition="bottom"
                                direction="ltr"
                                dropdownHeight="1000px"
                                onChange={(values) => {
                                  updateFieldValue(
                                    index,
                                    "productName",
                                    values[0]?.productName
                                  );
                                  updateFieldValue(
                                    index,
                                    "productAmount",
                                    values[0]?.price
                                  );
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="productAmount[]"
                                id={`productAmount${field.sr_no}`}
                                data-srno={field.sr_no}
                                className="form-control input-sm number_only productAmount"
                                value={field.productAmount}
                                readOnly
                                onChange={(e) =>
                                  updateFieldValue(
                                    index,
                                    "productAmount",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="depositAmount[]"
                                id={`depositAmount${field.sr_no}`}
                                data-srno={field.sr_no}
                                className="form-control input-sm depositAmount"
                                value={field.depositAmount}
                                onChange={(e) =>
                                  updateFieldValue(
                                    index,
                                    "depositAmount",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="installment[]"
                                id={`installment${field.sr_no}`}
                                data-srno={field.sr_no}
                                className="form-control input-sm number_only installment"
                                value={field.installment}
                                onChange={(e) =>
                                  updateFieldValue(
                                    index,
                                    "installment",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="advance[]"
                                id={`advance${field.sr_no}`}
                                data-srno={field.sr_no}
                                className="form-control input-sm advance"
                                value={field.advance}
                                onChange={(e) =>
                                  updateFieldValue(
                                    index,
                                    "advance",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="balance[]"
                                id={`balance${field.sr_no}`}
                                data-srno={field.sr_no}
                                className="form-control input-sm balance"
                                value={field.balance}
                                onChange={(e) =>
                                  updateFieldValue(
                                    index,
                                    "balance",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            {fields[index]?.sr_no == 1 ? (
                              <td>
                                <button
                                  type="button"
                                  name="add_row"
                                  id="add_row"
                                  className="btn btn-success btn-xs"
                                  onClick={addInputField}
                                >
                                  +
                                </button>
                              </td>
                            ) : (
                              ""
                            )}
                            {fields[index]?.sr_no != 1 ? (
                              <td>
                                <button
                                  type="button"
                                  name="add_row"
                                  id="add_row"
                                  className="btn btn-danger btn-xs remove_row"
                                  onClick={() => removeInputField(index)}
                                >
                                  X
                                </button>
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <br />
                    <center>
                      <div className="col-md-3 row">
                        <label htmlFor="whom">समूह संग्रह राशि :</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="समूह संग्रह राशि"
                          readOnly
                          placeholder="समूह संग्रह राशि"
                          value={totalInstallment}
                        />
                      </div>
                    </center>
                  </div>
                </div>
                <center>
                  <div className="card-footer">
                    <input
                      style={{
                        backgroundColor: "#00a65a",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Save"
                      name="add_save"
                      disabled={isSubmit}
                      onClick={Submit}
                    />
                    <input
                      style={{
                        backgroundColor: "#f39c12",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Close"
                      name="add_save"
                      onClick={handleClose}
                    />
                  </div>
                  <StatusToast />
                </center>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default CollectionSheet;
