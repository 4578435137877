import Header from "../components/header/Header";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";

import { useEffect, useState } from "react";
import axios, { CancelToken } from "../api/axios";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import StatusToast from "../components/StatusToast";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { DatePicker } from "reactstrap-date-picker";
import { hasData } from "jquery";

const ActionDropdown = ({ data, loadData }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const handleDelete = async () => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (!willDelete) return;
    try {
      let id = data?._id;
      let response = axios.delete("/collection-sheet/" + id, {
        headers: { authorization: "Bearer " + auth.token },
      });
      response = await toast.promise(response, {
        pending: "Deleting Entry",
        success: "Entry Deleted Successfully!",
        error: "Something went Wrong!",
      });
      console.log(response.data);

      const cancelToken = CancelToken.source();
      loadData(cancelToken);
    } catch (err) {
      console.log(err);
    }
  };
  const handleEdit = () => {
    console.log(data);
    navigate("/collection/sheet/add", { state: data });
  };

  const handleView = () => {
    navigate("/collection/sheet/view", { state: data });
  };

  return (
    <>
      <DropdownButton id="dropdown-basic-button" title="Action">
        <Dropdown.Item onClick={handleView}>
          View Collection Sheet
        </Dropdown.Item>
        {/* <Dropdown.Item onClick={handleEdit}>Edit</Dropdown.Item> */}
        {/* <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item> */}
      </DropdownButton>
    </>
  );
};

const CollectionSheetList = () => {
  const { SearchBar } = Search;
  const [data, setData] = useState([]);
  const [searchDate, setsearchDate] = useState(null);
  const { auth } = useAuth();
  const [all, setall] = useState(true);

  const DateForamate = (inputDateString) => {
    if (!inputDateString) {
      return "";
    }
    const parts = inputDateString?.split("T")[0]?.split("-");
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  const loadData = async (cancelToken, customDate = false) => {
    let id;
    if (customDate) {
      setall(false);
      id = searchDate;
    } else {
      id = null;
      setsearchDate(null);
      setall(true);
    }
    try {
      const response = await axios.get("/center/list/" + id, {
        headers: { authorization: "bearer " + auth.token },
        cancelToken: cancelToken.token,
      });
      console.log(response?.data);
      if (response?.data?.success) {
        setData(response.data.list);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (searchDate) {
      const cancelToken = CancelToken.source();
      loadData(cancelToken, true);
    }
  }, [searchDate]);

  useEffect(() => {
    const cancelToken = CancelToken.source();
    loadData(cancelToken);
  }, []);
  const columns = [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      sort: true,
      formatExtraData: loadData,
      formatter: (c, row, i, extra) => {
        return <ActionDropdown data={row} loadData={extra} />;
      },
    },

    {
      dataField: "center",
      text: "ग्रुप का नाम ",
    },
    {
      dataField: "centerId",
      text: "ग्रुप आईडी ",
    },
    {
      dataField: "disbursementDate",
      text: "संग्रहण की दिनांक",
      formatter: (c, row, i, extra) => {
        return DateForamate(row?.paymentDate);
      },
    },
    {
      dataField: "staffName",
      text: "स्टाफ का नाम",
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data?.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  return (
    <>
      <Header page="Collection Sheet List" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">संग्रह पत्रक सूची</h3>
              </div>
              <div className="card-body">
                {/* <Link to="/collection/sheet/add">
                  <button
                    style={{
                      float: "right",
                      width: 200,
                      backgroundColor: "#00c0ef",
                      color: "white",
                      height: 41,
                    }}
                  >
                    <i className="fa fa-plus"></i>
                    {" नया संग्रह पत्रक"}
                  </button>
                </Link> */}
                <br></br>
                <br></br>
                <ToolkitProvider
                  keyField="id"
                  data={data}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <div>
                      <div
                        className="d-flex justify-content-end"
                        id="search_box"
                      >
                        <span style={{ marginRight: "10px", display: "flex" }}>
                          <DatePicker
                            dateFormat="DD/MM/YYYY"
                            style={{ width: "140px" }}
                            showClearButton={false}
                            id="from-date"
                            value={searchDate}
                            onChange={(e) => setsearchDate(e)}
                            placeholder="Search By Date"
                          />
                        </span>
                        <span style={{ marginRight: "5px", display: "flex" }}>
                          <Button
                            style={{ width: "50px", height: "40px" }}
                            variant="outline-primary"
                            onClick={(e) => {
                              let cancelToken = CancelToken.source();
                              loadData(cancelToken);
                            }}
                            active={all}
                          >
                            All
                          </Button>{" "}
                        </span>
                        <br></br>
                        {/* <h5>Input something at below input field:</h5> */}
                        <SearchBar {...props.searchProps} />
                      </div>
                      <hr />
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        noDataIndication="Table is Empty"
                        striped
                        {...props.baseProps}
                        pagination={paginationFactory(options)}
                        filter={filterFactory()}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>

        <StatusToast />
      </section>
    </>
  );
};
export default CollectionSheetList;
