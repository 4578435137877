import { useRef, useState } from "react";
import Header from "../components/header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import axios, { CancelToken } from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useEffect } from "react";
import Select from "react-dropdown-select";

const AddUser = () => {
  const location = useLocation();
  const data = location.state;
  console.log(data);
  const [firstName, setFirstName] = useState(data?.firstName || "");
  const [lastName, setLastName] = useState(data?.lastName || "");
  const [mobile, setMobile] = useState(data?.mobile || "");
  const [email, setEmail] = useState(data?.email || "");
  const [password, setPassword] = useState(data?.password || "");
  const [role, setRole] = useState(data?.role || "0");
  const [roleList, setRoleList] = useState([]);
  const [storeList, setstoreList] = useState([]);
  const [store, setstore] = useState(data?.storeId || "");
  const navigate = useNavigate();
  const [isSubmit, setisSubmit] = useState(false);
  const { auth } = useAuth();

  const [staffId, setstaffId] = useState(data?.staffId || "");
  function generateStaffID() {
    const prefix = "EMPLOYEE_";
    const randomNum = Math.floor(Math.random() * 1000) + 100; // Generate a random number between 100 and 999
    return `${prefix}${randomNum}`;
  }

  useEffect(() => {
    if (!staffId) {
      setstaffId(generateStaffID());
    }
  }, []);

  useEffect(() => {
    const lists = async () => {
      try {
        const response = await axios.get("/role/list", {
          headers: { authorization: "bearer " + auth.token },
        });
        console.log(response?.data?.list);
        if (response?.data?.success) {
          setRoleList(response?.data?.list);
        }
        const ress = await axios.get("/store/list", {
          headers: { authorization: "bearer " + auth.token },
        });
        console.log(response?.data?.list);
        if (response?.data?.success) {
          setstoreList(ress?.data?.list);
        }
      } catch (err) {
        swal({ title: "Error!", text: `${err.message}`, icon: "error" });
        console.log("this is called " + err);
      }
    };
    lists();
  }, []);

  const handleClose = async () => {
    navigate("/");
  };

  const addUser = async () => {
    console.log(firstName, lastName, mobile, email, password, role);
    if (!firstName || !lastName || !email || !password || !role) {
      return swal({
        title: "Failure!",
        text: `Please Fill All Fields`,
        icon: "error",
      });
    }

    console.log("add user is  called");
    if (isSubmit) return;
    setisSubmit(true);

    console.log(firstName, lastName, mobile, email, password, role);

    try {
      const id = data?._id;
      const url = data?._id ? "/users/" + id : "/users/add";
      const response = await axios.post(
        url,
        {
          id,
          firstName,
          lastName,
          mobile,
          email,
          password,
          role,
          storeId: store,
          staffId,
        },
        {
          headers: { authorization: "bearer " + auth.token },
        }
      );
      console.log(response);
      if (response?.data?.success) {
        swal({
          title: "Success!",
          text: `${response.data.message}`,
          icon: "success",
        });

        navigate("/user/list");
      } else {
        swal({
          title: "Failure!",
          text: `${response.data.message}`,
          icon: "error",
        });
      }
    } catch (err) {
      swal({ title: "Error!", text: `${err.message}`, icon: "error" });

      console.log("this is called " + err);
    } finally {
      setisSubmit(false);
    }
  };

  return (
    <>
      <Header page="Add User" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Create User</h3>
              </div>
              <form className="needs-validation" noValidate>
                <div className="card-body">
                  <div className="form-group">
                    <div className="row" id="2">
                      <div className="col-md-3">
                        <label htmlFor="name">First Name:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Enter First Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          Name is Required!
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="name">Last Name:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Enter Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          Name is Required!
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="username"> Mobile: </label>
                        <input
                          type="text"
                          className="form-control"
                          id="number"
                          placeholder="Enter Number"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          Mobile is Required!
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="email">Email:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="email"
                          value={email}
                          placeholder="Enter Email Address"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          Email is Required!
                        </div>
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="whom">Password:</label>
                        <input
                          required
                          type="password"
                          className="form-control"
                          id="password"
                          placeholder="Enter Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          Password is Required!
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="role">Role:</label>
                        <Select
                          options={roleList}
                          searchBy="roleName"
                          labelField="roleName"
                          valueField="roleName"
                          multi={false}
                          loading={false}
                          handle={true}
                          addPlaceholder="Search"
                          color="#007bff"
                          clearable={false}
                          keepSelectedInList={true}
                          closeOnSelect={true}
                          dropdownPosition="bottom"
                          direction="ltr"
                          dropdownHeight="1000px"
                          onChange={(values) => {
                            console.log(values[0]?.roleName);
                            setRole(values[0]?.roleName);
                          }}
                        />
                      </div>
                      {auth?.user?.role == "admin" ? (
                        <div className="col-md-3">
                          <label htmlFor="role">Store:</label>
                          <Select
                            options={storeList}
                            searchBy="storeName"
                            labelField="storeName"
                            valueField="storeName"
                            multi={false}
                            loading={false}
                            handle={true}
                            addPlaceholder="Search"
                            color="#007bff"
                            clearable={false}
                            keepSelectedInList={true}
                            closeOnSelect={true}
                            dropdownPosition="bottom"
                            direction="ltr"
                            dropdownHeight="1000px"
                            onChange={(values) => {
                              console.log(values[0]?._id);
                              setstore(values[0]?._id);
                            }}
                          />
                        </div>
                      ) : (
                        <div className="col-md-3">
                          <label htmlFor="name">Staff ID:</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            id="स्टाफ"
                            placeholder="Staff ID"
                            readOnly
                            value={staffId}
                            onChange={(e) => setstaffId(e.target.value)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <center>
                  <div className="card-footer">
                    <input
                      style={{
                        backgroundColor: "#00a65a",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Save"
                      name="add_save"
                      disabled={isSubmit}
                      onClick={addUser}
                    />
                    <input
                      style={{
                        backgroundColor: "#f39c12",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Close"
                      name="add_save"
                      onClick={handleClose}
                    />
                  </div>
                  <StatusToast />
                </center>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AddUser;
