import { useRef, useState } from "react";
import Header from "../components/header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import axios, { CancelToken } from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useEffect } from "react";
import Select from "react-dropdown-select";

const AddProduct = () => {
  const location = useLocation();
  const data = location.state;
  const [productName, setproductName] = useState(data?.productName || "");
  const [price, setprice] = useState(data?.price || "");
  const [quantity, setquantity] = useState(data?.quantity || "");
  const [stockAlert, setstockAlert] = useState(data?.stockAlert || "");
  const [description, setdescription] = useState(data?.description || "");

  const navigate = useNavigate();
  const [isSubmit, setisSubmit] = useState(false);
  const { auth } = useAuth();
  const [categoryList, setcategoryList] = useState([]);
  const [categoryName, setcategoryName] = useState(data?.categroyName);

  useEffect(() => {
    const categoryList = async () => {
      try {
        let response = await axios.get("/category/list", {
          headers: { authorization: "bearer " + auth.token },
        });

        if (response) {
          setcategoryList(response?.data?.list);
        } else {
          swal({
            title: "Error!",
            text: `${response?.data?.message}`,
            icon: "error",
          });
        }
      } catch (err) {
        swal({ title: "Error!", text: `${err.message}`, icon: "error" });
        console.log(err.message);
      }
    };
    categoryList();
  }, []);

  const handleClose = async () => {
    navigate("/");
  };

  const addUser = async () => {
    if (!productName || !quantity || !stockAlert || !price) {
      return swal({
        title: "Failure!",
        text: `Please Fill All Fields`,
        icon: "error",
      });
    }

    if (isSubmit) return;
    setisSubmit(true);

    try {
      const id = data?._id;
      const url = data?._id ? "/product/" + id : "/product/add";
      const response = await axios.post(
        url,
        {
          productName,
          price,
          quantity,
          stockAlert,
          description,
          category: categoryName,
        },
        {
          headers: { authorization: "bearer " + auth.token },
        }
      );
      console.log(response);
      if (response?.data?.success) {
        swal({
          title: "Success!",
          text: `${response.data.message}`,
          icon: "success",
        });

        navigate("/product/list");
      } else {
        swal({
          title: "Failure!",
          text: `${response.data.message}`,
          icon: "error",
        });
      }
    } catch (err) {
      swal({ title: "Error!", text: `${err.message}`, icon: "error" });
    } finally {
      setisSubmit(false);
    }
  };

  return (
    <>
      <Header page="Add Prodect" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Create Product</h3>
              </div>
              <form className="needs-validation" noValidate>
                <div className="card-body">
                  <div className="form-group">
                    <div className="row" id="2">
                      <div className="col-md-3">
                        <label htmlFor="name">Product Name:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Enter Product Name"
                          value={productName}
                          onChange={(e) => setproductName(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          Name is Required!
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="username"> price: </label>
                        <input
                          type="Number"
                          className="form-control"
                          id="number"
                          placeholder="Enter Price"
                          value={price}
                          onChange={(e) => setprice(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          price is Required!
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="quantity">quantity:</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="quantity"
                          value={quantity}
                          placeholder="Enter Quantity "
                          onChange={(e) => setquantity(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          quantity is Required!
                        </div>
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="whom">Stock Alert Quantity:</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="stockAlert"
                          placeholder="Enter stockAlert"
                          value={stockAlert}
                          onChange={(e) => setstockAlert(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          stockAlert is Required!
                        </div>
                      </div>

                      <br />
                      <br />
                      <br />
                      <br />
                      <div className="col-md-3">
                        <label htmlFor="role">Product Category:</label>
                        <Select
                          options={categoryList}
                          searchBy="categoryName"
                          labelField="categoryName"
                          valueField="categoryName"
                          multi={false}
                          loading={false}
                          handle={true}
                          addPlaceholder="Search"
                          color="#007bff"
                          clearable={false}
                          keepSelectedInList={true}
                          closeOnSelect={true}
                          dropdownPosition="bottom"
                          direction="ltr"
                          dropdownHeight="1000px"
                          onChange={(values) => {
                            setcategoryName(values[0]?.categoryName);
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="name">Product Description:</label>
                        <textarea
                          required
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Product Description"
                          value={description}
                          onChange={(e) => setdescription(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <center>
                  <div className="card-footer">
                    <input
                      style={{
                        backgroundColor: "#00a65a",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Save"
                      name="add_save"
                      disabled={isSubmit}
                      onClick={addUser}
                    />
                    <input
                      style={{
                        backgroundColor: "#f39c12",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Close"
                      name="add_save"
                      onClick={handleClose}
                    />
                  </div>
                  <StatusToast />
                </center>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AddProduct;
