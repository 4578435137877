import React, { useEffect } from "react";
import Header from "../components/header/Header";
import { useState } from "react";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";

const AddCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state;
  const { auth } = useAuth();

  const [categoryName, setname] = useState(data?.categoryName || "");
  const [description, setdescription] = useState(data?.description || "");
  const [isSubmit, setisSubmit] = useState(false);

  const submitForm = async () => {
    if (!categoryName) {
      return swal({
        title: "Failure!",
        text: `Please Fill Category categoryName`,
        icon: "error",
      });
    }
    setisSubmit(true);
    try {
      const id = data?._id;
      const url = id ? "/category/" + id : "/category/add";
      const response = await axios.post(
        url,
        {
          categoryName,
          description,
        },
        {
          headers: { authorization: "bearer " + auth.token },
        }
      );
      console.log(response);
      if (response?.data?.success) {
        swal({
          title: "Success!",
          text: `${response.data.message}`,
          icon: "success",
        });

        navigate("/category/list");
      } else {
        swal({
          title: "Failure!",
          text: `${response.data.message}`,
          icon: "error",
        });
      }
    } catch (err) {
      swal({ title: "Error!", text: `${err.message}`, icon: "error" });

      console.log("this is called " + err);
    } finally {
      setisSubmit(false);
    }
  };

  const handleClose = async () => {
    navigate("/");
  };

  return (
    <>
      <Header page="Add Product Category" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Create New Product Category</h3>
              </div>
              <form className="needs-validation" noValidate>
                <div className="card-body">
                  <div className="form-group">
                    <div className="row" id="2">
                      <div className="col-md-6">
                        <label htmlFor="name">Category Name:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Enter Category Name"
                          value={categoryName}
                          onChange={(e) => setname(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          Name is Required!
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="name">Description:</label>
                        <textarea
                          required
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Enter Description"
                          value={description}
                          onChange={(e) => setdescription(e.target.value)}
                        />
                        <div className="invalid-feedback">
                          Name is Required!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <center>
                  <div className="card-footer">
                    <input
                      style={{
                        backgroundColor: "#00a65a",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Save"
                      name="add_save"
                      disabled={isSubmit}
                      onClick={submitForm}
                    />
                    <input
                      style={{
                        backgroundColor: "#f39c12",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Close"
                      name="add_save"
                      onClick={handleClose}
                    />
                  </div>
                  <StatusToast />
                </center>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddCategory;
