import { useRef, useState } from "react";
import Header from "../components/header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import axios, { CancelToken } from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useEffect } from "react";
import Select from "react-dropdown-select";

const AddDue = () => {
  const location = useLocation();
  const data = location.state;
  console.log(data);

  const navigate = useNavigate();
  const [isSubmit, setisSubmit] = useState(false);
  const { auth } = useAuth();
  const [groupList, setgroupList] = useState({});
  const [center, setcenter] = useState("");
  useEffect(() => {
    const groupList = async () => {
      try {
        const response = await axios.get("/center/list/" + null, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setgroupList(response.data.list);
      } catch (err) {
        console.log(err);
      }
    };
    groupList();
  }, []);

  const [memberList, setmemberList] = useState([]);
  const [member, setmember] = useState("");

  useEffect(() => {
    const memberList = async () => {
      setmember("");
      setemidata(null);
      try {
        const response = await axios.get("/due/member-list/" + center, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setmemberList(response.data.list);
      } catch (err) {
        console.log(err);
      }
    };
    memberList();
  }, [center]);

  const [emidata, setemidata] = useState(null);
  const [moreInfo, setmoreInfo] = useState({});

  useEffect(() => {
    const payEmi = async () => {
      try {
        const response = await axios.get("/due/pay-emi/" + member, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setemidata(response.data.list);
        setmoreInfo(response.data.moreDetails);
      } catch (err) {
        console.log(err);
      }
    };
    payEmi();
  }, [member]);

  const handleClose = async () => {
    navigate("/");
  };

  const DateForamate = (inputDateString) => {
    if (!inputDateString) {
      return "";
    }
    const parts = inputDateString.split("T")[0].split("-"); // Split and extract date parts
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  const AddDue = async () => {
    if (!emidata) {
      return swal({
        title: "Warning!",
        text: `No EMI Found`,
        icon: "warning",
      });
    }

    setisSubmit(true);

    try {
      const response = await axios.get("/due/emi/" + emidata?._id, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      if (response?.data?.success) {
        swal({
          title: "Success!",
          text: `${response.data.message}`,
          icon: "success",
        });

        navigate("/due/list");
      } else {
        swal({
          title: "Failure!",
          text: `${response.data.message}`,
          icon: "error",
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setisSubmit(false);
    }
  };

  return (
    <>
      <Header page="Add Due" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Add Due</h3>
              </div>
              <form className="needs-validation" noValidate>
                <div className="card-body">
                  <div className="form-group">
                    <div className="col-md-6 ">
                      <label htmlFor="role">Select Group:</label>
                      <Select
                        options={groupList}
                        searchBy="center"
                        labelField="center"
                        valueField="center"
                        multi={false}
                        loading={false}
                        handle={true}
                        addPlaceholder="Search"
                        color="#007bff"
                        clearable={true}
                        keepSelectedInList={true}
                        closeOnSelect={true}
                        dropdownPosition="bottom"
                        direction="ltr"
                        dropdownHeight="1000px"
                        onChange={(values) => {
                          setcenter(values[0]?.centerId);
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="role">Select Member:</label>
                      <Select
                        options={memberList}
                        searchBy="customer"
                        labelField="customer"
                        valueField="customer"
                        multi={false}
                        loading={false}
                        handle={true}
                        addPlaceholder="Search"
                        color="#007bff"
                        clearable={true}
                        keepSelectedInList={true}
                        closeOnSelect={true}
                        dropdownPosition="bottom"
                        direction="ltr"
                        dropdownHeight="1000px"
                        onChange={(values) => {
                          setmember(values[0]?._id);
                        }}
                      />
                    </div>
                    <br />
                    {emidata ? (
                      <>
                        <div
                          style={{
                            border: "1px solid  #E5E4E2",
                            borderRadius: "10px",
                          }}
                        >
                          <div
                            style={{
                              border: "1px solid  #E5E4E2",
                              borderRadius: "10px",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Verdana",
                                fontWeight: "bolder",
                                fontSize: "25px",
                              }}
                            >
                              1. EMI Details
                            </span>
                          </div>
                          {/* </h4> */}
                          <div className="row">
                            <div className="col-md-6">
                              <label
                                htmlfor="name"
                                style={{
                                  fontFamily: '"Verdana", sans-serif',
                                  lineHeight: "1.1",
                                  color: "#222222",
                                  margin: 0,
                                  padding: 10,
                                  textAlign: "center",
                                  fontWeight: 550,
                                  fontSize: 17,
                                }}
                              >
                                Center ID:
                              </label>
                              <span
                                style={{
                                  fontSize: 15,
                                  lineHeight: "1.1",
                                  color: "#222222",
                                  margin: 0,
                                  padding: 10,
                                  textAlign: "center",
                                }}
                              >
                                {moreInfo.centerId}
                              </span>
                            </div>
                            <div className="col-md-6 ">
                              <label
                                htmlfor="name"
                                style={{
                                  fontFamily: '"Verdana", sans-serif',
                                  lineHeight: "1.1",
                                  color: "#222222",
                                  margin: 0,
                                  padding: 10,
                                  textAlign: "center",
                                  fontWeight: 550,
                                  fontSize: 17,
                                }}
                              >
                                Staff Name :
                              </label>
                              <span
                                style={{
                                  fontSize: 15,
                                  lineHeight: "1.1",
                                  color: "#222222",
                                  margin: 0,
                                  padding: 10,
                                  textAlign: "center",
                                  fontFamily: '"Verdana", sans-serif',
                                }}
                              >
                                {moreInfo.staffName}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <label
                                htmlfor="name"
                                style={{
                                  fontFamily: '"Verdana", sans-serif',
                                  lineHeight: "1.1",
                                  color: "#222222",
                                  margin: 0,
                                  padding: 10,
                                  textAlign: "center",
                                  fontWeight: 550,
                                  fontSize: 17,
                                }}
                              >
                                Customer Name :
                              </label>
                              <span
                                style={{
                                  fontSize: 15,
                                  lineHeight: "1.1",
                                  color: "#222222",
                                  margin: 0,
                                  padding: 10,
                                  textAlign: "center",
                                  fontFamily: '"Verdana", sans-serif',
                                }}
                              >
                                {moreInfo?.customer}
                              </span>
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlfor="name"
                                style={{
                                  fontFamily: '"Verdana", sans-serif',
                                  lineHeight: "1.1",
                                  color: "#222222",
                                  margin: 0,
                                  padding: 10,
                                  textAlign: "center",
                                  fontWeight: 550,
                                  fontSize: 17,
                                }}
                              >
                                Parent Name :
                              </label>
                              <span
                                style={{
                                  fontSize: 15,
                                  lineHeight: "1.1",
                                  color: "#222222",
                                  margin: 0,
                                  padding: 10,
                                  textAlign: "center",
                                  fontFamily: '"Verdana", sans-serif',
                                }}
                              >
                                {moreInfo?.parent}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <label
                                htmlfor="name"
                                style={{
                                  fontFamily: '"Verdana", sans-serif',
                                  lineHeight: "1.1",
                                  color: "#222222",
                                  margin: 0,
                                  padding: 10,
                                  textAlign: "center",
                                  fontWeight: 550,
                                  fontSize: 17,
                                }}
                              >
                                Mobile :
                              </label>
                              <span
                                style={{
                                  fontSize: 15,
                                  lineHeight: "1.1",
                                  color: "#222222",
                                  margin: 0,
                                  padding: 10,
                                  textAlign: "center",
                                  fontFamily: '"Verdana", sans-serif',
                                }}
                              >
                                {moreInfo?.mobile}
                              </span>
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlfor="name"
                                style={{
                                  fontFamily: '"Verdana", sans-serif',
                                  lineHeight: "1.1",
                                  color: "#222222",
                                  margin: 0,
                                  padding: 10,
                                  textAlign: "center",
                                  fontWeight: 550,
                                  fontSize: 17,
                                }}
                              >
                                EMI :
                              </label>
                              <span
                                style={{
                                  fontSize: 15,
                                  lineHeight: "1.1",
                                  color: "#222222",
                                  margin: 0,
                                  padding: 10,
                                  textAlign: "center",
                                  fontFamily: '"Verdana", sans-serif',
                                }}
                              >
                                {emidata?.emiAmount}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <label
                                htmlfor="name"
                                style={{
                                  fontFamily: '"Verdana", sans-serif',
                                  lineHeight: "1.1",
                                  color: "#222222",
                                  margin: 0,
                                  padding: 10,
                                  textAlign: "center",
                                  fontWeight: 550,
                                  fontSize: 17,
                                }}
                              >
                                EMI No :
                              </label>
                              <span
                                style={{
                                  fontSize: 15,
                                  lineHeight: "1.1",
                                  color: "#222222",
                                  margin: 0,
                                  padding: 10,
                                  textAlign: "center",
                                  fontFamily: '"Verdana", sans-serif',
                                }}
                              >
                                {emidata?.emiNumber}
                              </span>
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlfor="name"
                                style={{
                                  fontFamily: '"Verdana", sans-serif',
                                  lineHeight: "1.1",
                                  color: "#222222",
                                  margin: 0,
                                  padding: 10,
                                  textAlign: "center",
                                  fontWeight: 550,
                                  fontSize: 17,
                                }}
                              >
                                EMI Pay Date :
                              </label>
                              <span
                                style={{
                                  fontSize: 15,
                                  lineHeight: "1.1",
                                  color: "#222222",
                                  margin: 0,
                                  padding: 10,
                                  textAlign: "center",
                                  fontFamily: '"Verdana", sans-serif',
                                }}
                              >
                                {DateForamate(emidata?.emiPayDate)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            border: "1px solid  #E5E4E2",
                            borderRadius: "10px",
                          }}
                        >
                          <center>
                            <p style={{ color: "red", padding: "20px" }}>
                              No Emi Details Found For This Given Data
                            </p>
                          </center>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <center>
                  <div className="card-footer">
                    <input
                      style={{
                        backgroundColor: "#00a65a",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Update Due"
                      name="add_save"
                      disabled={isSubmit}
                      onClick={AddDue}
                    />
                    <input
                      style={{
                        backgroundColor: "#f39c12",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Close"
                      name="add_save"
                      onClick={handleClose}
                    />
                  </div>
                  <StatusToast />
                </center>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AddDue;
