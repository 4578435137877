import Header from "../components/header/Header";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useEffect, useState } from "react";
import axios, { CancelToken } from "../api/axios";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import StatusToast from "../components/StatusToast";
import { Button, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import Select from "react-dropdown-select";
import Loader from "../loader/loader";
const { ExportCSVButton } = CSVExport;

const QuantityModel = ({ data, handleClose, show, loadData }) => {
  const { auth } = useAuth();
  const [quantity, setquantity] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const addQuantity = async () => {
    if (isSubmit) return;
    if (!quantity) {
      toast("Missing Quantity!", { type: "warning" });
      return;
    }
    setIsSubmit(true);
    console.log(data?._id);
    console.log(quantity);
    try {
      let response = axios.post(
        "/product/quantity/" + data?._id,
        {
          quantity,
        },
        {
          headers: { authorization: "Bearer " + auth.token },
        }
      );
      response = await toast.promise(response, {
        pending: "Adding Quantity..",
        success: "Quantity Added Successfully!",
        error: "Something went Wrong!",
      });
      setquantity("");
      handleClose();
      const cancelToken = CancelToken.source();
      loadData(cancelToken);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmit(false);
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add quantity</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="status">Quantity</label>
          <br />
          <input
            className="form-control"
            id="status"
            onChange={(e) => setquantity(e.target.value)}
            value={quantity}
            placeholder="Add Quantity"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={addQuantity}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ActionDropdown = ({ data, loadData }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleDelete = async () => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (!willDelete) return;
    try {
      let response = axios.delete("/product/" + data?._id, {
        headers: { authorization: "Bearer " + auth.token },
      });
      response = await toast.promise(response, {
        pending: "Deleting Entry",
        success: "Entry Deleted Successfully!",
        error: "Something went Wrong!",
      });
      console.log(response.data);

      const cancelToken = CancelToken.source();
      loadData(cancelToken);
    } catch (err) {
      console.log(err);
    }
  };
  const handleEdit = () => {
    navigate("/product/add", { state: data });
  };

  return (
    <>
      <DropdownButton id="dropdown-basic-button" title="Action">
        <Dropdown.Item onClick={handleEdit}>Edit</Dropdown.Item>
        <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
        <Dropdown.Item onClick={handleShow}>Update Quantity</Dropdown.Item>
      </DropdownButton>
      <QuantityModel
        show={show}
        handleClose={handleClose}
        data={data}
        loadData={loadData}
      />
      <StatusToast />
    </>
  );
};

const ProductList = () => {
  const { SearchBar } = Search;
  const [data, setData] = useState([]);
  const { auth } = useAuth();
  const [status, setStatus] = useState("0");

  const [categoryList, setcategoryList] = useState([]);
  const [categoryName, setcategoryName] = useState("");

  const [productList, setproductList] = useState([]);
  const [productName, setproductName] = useState("");
  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {
    const categoryList = async () => {
      try {
        let response = await axios.get("/category/list", {
          headers: { authorization: "bearer " + auth.token },
        });

        if (response) {
          setcategoryList(response?.data?.list);
        } else {
          swal({
            title: "Error!",
            text: `${response?.data?.message}`,
            icon: "error",
          });
        }
      } catch (err) {
        swal({ title: "Error!", text: `${err.message}`, icon: "error" });
        console.log(err.message);
      }
    };
    const productList = async () => {
      try {
        let response = await axios.get("/product/list", {
          headers: { authorization: "bearer " + auth.token },
        });

        if (response) {
          setproductList(response?.data?.list);
        } else {
          swal({
            title: "Error!",
            text: `${response?.data?.message}`,
            icon: "error",
          });
        }
      } catch (err) {
        swal({ title: "Error!", text: `${err.message}`, icon: "error" });
        console.log(err.message);
      }
    };
    productList();
    categoryList();
  }, []);

  const smartSearch = async (cancelToken) => {
    if (!categoryName) {
      return swal({
        title: "Oops!",
        text: `Please Select Product Category`,
        icon: "warning",
      });
    }
    setisLoading(true);
    setStatus("1");
    try {
      const response = await axios.post(
        "/product/list/smart-search",
        {
          productName,
          categoryName,
        },
        {
          headers: { authorization: "bearer " + auth.token },
          cancelToken: cancelToken.token,
        }
      );
      console.log(response.data);
      if (response?.data?.success) {
        setData(response.data.list);
      }
      setisLoading(false);
    } catch (err) {
      setisLoading(false);
      console.error(err);
    }
  };

  const loadData = async (cancelToken) => {
    try {
      const response = await axios.get("/product/list", {
        headers: { authorization: "bearer " + auth.token },
        cancelToken: cancelToken.token,
      });
      console.log(response.data);
      if (response?.data?.success) {
        setData(response.data.list);
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    const cancelToken = CancelToken.source();
    loadData(cancelToken);
  }, []);
  const columns = [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      sort: true,
      csvExport: false,
      formatExtraData: loadData,
      formatter: (c, row, i, extra) => {
        return <ActionDropdown data={row} loadData={extra} />;
      },
    },
    {
      dataField: "productName",
      text: "Product Name",
    },
    {
      dataField: "price",
      text: "Price",
    },
    {
      dataField: "quantity",
      text: "Available Quantity",
    },
    {
      dataField: "category",
      text: "Category",
    },
    {
      dataField: "stockAlert",
      text: "Stock Alert",
      formatter: (c, row, i, extra) => {
        if (row?.stockAlert < row?.quantity) {
          return (
            <button
              style={{
                backgroundColor: "#4caf50",
                color: "white",
                border: "none",
                padding: "5px 10px",
                cursor: "pointer",
                fontSize: "14px",
                borderRadius: "5px",
              }}
            >
              {row?.stockAlert}
            </button>
          );
        } else {
          return (
            <button
              style={{
                backgroundColor: "red",
                color: "white",
                border: "none",
                padding: "5px 10px",
                cursor: "pointer",
                fontSize: "14px",
                borderRadius: "5px",
              }}
            >
              {row?.stockAlert}
            </button>
          );
        }
      },
    },
    {
      dataField: "description",
      text: "Description",
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  return (
    <>
      <Header page="Product List" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Product List</h3>
              </div>
              <div className="card-body">
                <Link to="/product/add">
                  <button
                    style={{
                      float: "right",
                      width: 140,
                      backgroundColor: "#00c0ef",
                      color: "white",
                      height: 41,
                    }}
                  >
                    <i className="fa fa-plus"></i>
                    {"   New Product"}
                  </button>
                </Link>
                <h3>Search By Filter</h3>
                <br></br>
                <div className="row">
                  <div className="col-3">
                    <label htmlFor="role">Product Category:</label>
                    <Select
                      options={categoryList}
                      searchBy="categoryName"
                      labelField="categoryName"
                      valueField="categoryName"
                      multi={false}
                      loading={false}
                      handle={true}
                      addPlaceholder="Search"
                      color="#007bff"
                      clearable={true}
                      keepSelectedInList={true}
                      closeOnSelect={true}
                      dropdownPosition="bottom"
                      direction="ltr"
                      dropdownHeight="1000px"
                      onChange={(values) => {
                        setcategoryName(values[0]?.categoryName);
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <label htmlFor="role">Product Name:</label>
                    <Select
                      options={productList}
                      searchBy="productName"
                      labelField="productName"
                      valueField="productName"
                      multi={false}
                      loading={false}
                      handle={true}
                      addPlaceholder="Search"
                      color="#007bff"
                      clearable={true}
                      keepSelectedInList={true}
                      closeOnSelect={true}
                      dropdownPosition="bottom"
                      direction="ltr"
                      dropdownHeight="1000px"
                      onChange={(values) => {
                        setproductName(values[0]?.productName);
                      }}
                    />
                  </div>
                </div>
                <br></br>
                <div className="card-footer col-md-6">
                  <center>
                    {!isLoading ? (
                      <input
                        style={{
                          backgroundColor: "#00a65a",
                          color: "white",
                          width: "15%",
                          height: "38px",
                          border: "none",
                          marginRight: "40px",
                        }}
                        type="button"
                        value="Search"
                        name="add_Search"
                        onClick={smartSearch}
                      />
                    ) : (
                      <Loader isLoading={isLoading} />
                    )}
                  </center>
                </div>

                <br></br>
                <br></br>
                <hr></hr>

                <ToolkitProvider
                  keyField="id"
                  data={data}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <div>
                      <div
                        className="d-flex justify-content-end"
                        id="search_box"
                      >
                        <span
                          style={{
                            marginRight: "5px",
                            display: "flex",
                          }}
                        >
                          <Button
                            style={{ width: "60px", height: "40px" }}
                            variant="outline-primary"
                            onClick={(e) => {
                              setStatus("0");
                              let cancelToken = CancelToken.source();
                              loadData(cancelToken);
                            }}
                            active={status === "0" ? true : false}
                          >
                            All
                          </Button>{" "}
                        </span>
                        <span style={{ marginRight: "5px" }}>
                          <ExportCSVButton {...props.csvProps}>
                            Export Excel
                          </ExportCSVButton>
                        </span>
                        <br></br>
                        {/* <h5>Input something at below input field:</h5> */}
                        <SearchBar {...props.searchProps} />
                      </div>
                      <hr />
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        noDataIndication="Table is Empty"
                        striped
                        {...props.baseProps}
                        pagination={paginationFactory(options)}
                        filter={filterFactory()}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>

        <StatusToast />
      </section>
    </>
  );
};
export default ProductList;
