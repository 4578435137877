import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useState } from "react";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import { faBorderStyle } from "@fortawesome/free-solid-svg-icons";
var converter = require("number-to-words");

const OnBoardInvoicePrint = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state;
  console.log(data);
  const { auth } = useAuth();

  /**
   * Code For Go Reverce Page
   */

  const handleGoBack = () => {
    window.history.back();
  };

  /**
   * End
   */
  const DateForamate = (inputDateString) => {
    if (!inputDateString) {
      return "";
    }
    const parts = inputDateString.split("T")[0].split("-"); // Split and extract date parts
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  var targetRef = useRef(null);

  const handlePrint = () => {
    const targetElement = targetRef.current;

    if (targetElement) {
      window.print();
    }
  };

  return (
    <>
      {/* Header Wrapper Start */}
      <div className="container" id="section-to-print" ref={targetRef}>
        <div
          className="loan-application-main-wrapper"
          style={{
            width: "100%",
            margin: "0 auto",
            // border: "1px solid #111",
            // border: "1px solid #E5E4E2",
            padding: "0px 11px",
            // backgroundColor: "#E5E4E2",
            backgroundColor: "white",
          }}
        >
          <div className="">
            <div className="row">
              <div
                className="col-lg-12 col-md-12 col-12 mar pad"
                style={{ padding: 0, margin: 0 }}
              >
                <div
                  className="invoice-title"
                  style={{
                    width: "100%",
                    float: "left",
                    textAlign: "center" /* borderBottom: '1px solid #111', */,
                    paddingBottom: 2,
                  }}
                >
                  <p
                    className="title-heading"
                    style={{
                      fontFamily: '"Roboto", sans-serif',
                      lineHeight: "1.5",
                      color: "#222222",
                      margin: 0,
                      padding: 0,
                      textAlign: "center",
                      fontWeight: 600,
                      padding: 5,
                      textTransform: "uppercase",
                      fontSize: 75,
                    }}
                  >
                    <span>
                      Care Enterprises
                      <hr
                        style={{
                          width: "80%",
                          // borderTop: "7px solid #E5E4E2",
                          borderTop: "7px solid #000",
                          marginTop: "-9px",
                        }}
                      />
                    </span>
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "#111",
                      fontWeight: 800 /* lineHeight: 15, */,
                      fontSize: 27,
                    }}
                  >
                    Your Necessity is Our Responsibility
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "#111",
                      fontWeight: 800 /* lineHeight: 15, */,
                      fontSize: 27,
                    }}
                  >
                    33, Lal Bahadur Shashtri Marg, Panchampura, Ujjain (M.P.)
                    Call 9109206324
                  </p>
                  <p
                    style={{
                      margin: 0,
                      color: "#111",
                      fontWeight: 800 /* lineHeight: 15, */,
                      fontSize: 27,
                    }}
                  >
                    GSTIN : 23AUWPM2491B1ZJ
                  </p>
                </div>
                {/*  */}
                <div
                  className="di-invoice-main-wrapper"
                  style={{ width: "100%", float: "left" }}
                >
                  <br />
                  <div className="form-group">
                    <div
                      style={{
                        border: "1px solid  #E5E4E2",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          border: "1px solid  #E5E4E2",
                          borderRadius: "10px",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "Verdana",
                            fontWeight: "bolder",
                            fontSize: "25px",
                          }}
                        >
                          1. Bill Of Supply
                        </span>
                      </div>
                      {/* </h4> */}
                      <div className="row">
                        <div className="col-md-6">
                          <label
                            htmlfor="name"
                            style={{
                              fontFamily: '"Verdana", sans-serif',
                              lineHeight: "1.1",
                              color: "#222222",
                              margin: 0,
                              padding: 10,
                              textAlign: "center",
                              fontWeight: 550,
                              fontSize: 17,
                            }}
                          >
                            Invoice No. :
                          </label>
                          <span
                            style={{
                              fontSize: 15,
                              lineHeight: "1.1",
                              color: "#222222",
                              margin: 0,
                              padding: 10,
                              textAlign: "center",
                            }}
                          >
                            {data?.invoiceId}
                          </span>
                        </div>
                        <div className="col-md-6 ">
                          <label
                            htmlfor="name"
                            style={{
                              fontFamily: '"Verdana", sans-serif',
                              lineHeight: "1.1",
                              color: "#222222",
                              margin: 0,
                              padding: 10,
                              textAlign: "center",
                              fontWeight: 550,
                              fontSize: 17,
                            }}
                          >
                            Invoice Date :
                          </label>
                          <span
                            style={{
                              fontSize: 15,
                              lineHeight: "1.1",
                              color: "#222222",
                              margin: 0,
                              padding: 10,
                              textAlign: "center",
                              fontFamily: '"Verdana", sans-serif',
                            }}
                          >
                            {DateForamate(data?.date)}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <label
                          htmlfor="name"
                          style={{
                            fontFamily: '"Verdana", sans-serif',
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 550,
                            fontSize: 17,
                          }}
                        >
                          State :
                        </label>
                        <span
                          style={{
                            fontSize: 15,
                            lineHeight: "1.1",
                            color: "#222222",
                            margin: 0,
                            padding: 10,
                            textAlign: "center",
                            fontFamily: '"Verdana", sans-serif',
                          }}
                        >
                          Madhya Pradesh
                        </span>
                      </div>
                    </div>
                    <br />
                    <div
                      style={{
                        border: "1px solid  #E5E4E2",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          border: "1px solid  #E5E4E2",
                          borderRadius: "10px",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "Verdana",
                            fontWeight: "bolder",
                            fontSize: "25px",
                          }}
                        >
                          2. Customer Details
                        </span>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label
                            htmlfor="name"
                            style={{
                              fontFamily: '"Verdana", sans-serif',
                              lineHeight: "1.1",
                              color: "#222222",
                              margin: 0,
                              padding: 10,
                              textAlign: "center",
                              fontWeight: 550,
                              fontSize: 17,
                            }}
                          >
                            Name :
                          </label>
                          <span
                            style={{
                              fontSize: 15,
                              lineHeight: "1.1",
                              color: "#222222",
                              margin: 0,
                              padding: 10,
                              textAlign: "center",
                              fontFamily: '"Verdana", sans-serif',
                            }}
                          >
                            {data?.customer}
                          </span>
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlfor="name"
                            style={{
                              fontFamily: '"Verdana", sans-serif',
                              lineHeight: "1.1",
                              color: "#222222",
                              margin: 0,
                              padding: 10,
                              textAlign: "center",
                              fontWeight: 550,
                              fontSize: 17,
                            }}
                          >
                            Address :
                          </label>
                          <span
                            style={{
                              fontSize: 15,
                              lineHeight: "1.1",
                              color: "#222222",
                              margin: 0,
                              padding: 10,
                              textAlign: "center",
                              fontFamily: '"Verdana", sans-serif',
                            }}
                          >
                            {data?.gharkaavlokan}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label
                            htmlfor="name"
                            style={{
                              fontFamily: '"Verdana", sans-serif',
                              lineHeight: "1.1",
                              color: "#222222",
                              margin: 0,
                              padding: 10,
                              textAlign: "center",
                              fontWeight: 550,
                              fontSize: 17,
                            }}
                          >
                            GSTIN :
                          </label>
                          <span
                            style={{
                              fontSize: 15,
                              lineHeight: "1.1",
                              color: "#222222",
                              margin: 0,
                              padding: 10,
                              textAlign: "center",
                              fontFamily: '"Verdana", sans-serif',
                            }}
                          >
                            {data?.gstin}
                          </span>
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlfor="name"
                            style={{
                              fontFamily: '"Verdana", sans-serif',
                              lineHeight: "1.1",
                              color: "#222222",
                              margin: 0,
                              padding: 10,
                              textAlign: "center",
                              fontWeight: 550,
                              fontSize: 17,
                            }}
                          >
                            Mobile No. :
                          </label>
                          <span
                            style={{
                              fontSize: 15,
                              lineHeight: "1.1",
                              color: "#222222",
                              margin: 0,
                              padding: 10,
                              textAlign: "center",
                              fontFamily: '"Verdana", sans-serif',
                            }}
                          >
                            {data?.mobile}
                          </span>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div
                      style={{
                        border: "1px solid  #E5E4E2",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          border: "1px solid  #E5E4E2",
                          borderRadius: "10px",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "Verdana",
                            fontWeight: "bolder",
                            fontSize: "25px",
                          }}
                        >
                          3. Products Details
                        </span>
                      </div>
                      <table
                        id="invoice-item-table"
                        className="table table-bordered"
                      >
                        <tbody>
                          <tr>
                            <th width="5%">Sr No.</th>
                            <th width="35%">Description Of Goods</th>
                            <th width="10%">HSN Code / IMEI No</th>
                            <th width="15%">Quantity</th>
                            <th width="15%">Rate</th>
                            <th width="15%">Amount</th>
                            <th width="15%">Value Of Supply</th>
                          </tr>
                          {data?.data.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <span id="sr_no">{index + 1}</span>
                              </td>
                              <td>{item?.productName}</td>
                              <td>{item?.hsnCode || "ABC"}</td>
                              <td>{item?.productQuantity}</td>
                              <td>{item?.productAmount}</td>
                              <td>{item?.productAmount}</td>
                              <td>{item?.totalAmount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div
                      style={{
                        // border: "1px solid  #E5E4E2",
                        borderRadius: "10px",
                        padding: 10,
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Verdana",
                          // fontWeight: "bold",
                          fontSize: "20px",
                        }}
                      >
                        Amount In Word :{" "}
                        {converter.toWords(
                          parseFloat(+data?.totalAmount).toFixed(2)
                        )}
                      </span>
                    </div>
                    <br />
                    <div
                      style={{
                        border: "1px solid  #E5E4E2",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          border: "1px solid  #E5E4E2",
                          borderRadius: "10px",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "Verdana",
                            fontWeight: "bolder",
                            fontSize: "25px",
                          }}
                        >
                          4. Billing Details
                        </span>
                      </div>
                      <div className="table_box">
                        <div
                          className="tableBox row m-0"
                          style={{
                            border: "1px solid  #E5E4E2",
                            borderRadius: "10px",
                            // borderTop: "1px solid  #E5E4E2",
                            borderRadius: "10px",
                            lineHeight: "15px",
                            fontSize: "18px",
                          }}
                        >
                          <div
                            className="terms-text col-6"
                            style={{
                              lineHeight: "19px",
                              padding: "10px 10px",
                            }}
                          >
                            <ul
                              style={{
                                listStyle: "none",
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              <li
                                style={{
                                  lineHeight: "18px",
                                  fontWeight: 600,
                                  padding: 5,
                                }}
                              >
                                Terms &amp; Conditions
                              </li>
                              <li
                                style={{
                                  lineHeight: "18px",
                                  fontWeight: 600,
                                  padding: 5,
                                }}
                              >
                                1. Good received in good condition
                              </li>
                              <li
                                style={{
                                  lineHeight: "18px",
                                  fontWeight: 600,
                                  padding: 5,
                                }}
                              >
                                2. Good one sold will not be taken back
                              </li>
                              <li
                                style={{
                                  lineHeight: "18px",
                                  fontWeight: 600,
                                  padding: 5,
                                }}
                              >
                                3. We are not responsible for any loss or damage
                                in transit.
                              </li>
                              <li
                                style={{
                                  lineHeight: "18px",
                                  fontWeight: 600,
                                  padding: 5,
                                }}
                              >
                                4. Good are dispatched entirely at the risk of
                                customer
                              </li>
                              {/* <li
                                style={{
                                  lineHeight: "18px",
                                  fontWeight: 600,
                                  padding: 5,
                                }}
                              >
                                5. Penalty will be charge if invoice amount not
                                paid within Scheduled Time
                              </li> */}
                            </ul>
                          </div>
                          {/* <div
                            className="pl-left col-6"
                            style={{
                              lineHeight: "19px",
                              // borderLeft: "1px solid  #E5E4E2",
                              // borderRadius: "10px",
                              padding: 0,
                              paddingLeft: "40px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <div
                              className="terms-text"
                              style={{ padding: "4px 10px" }}
                            >
                              <ul
                                style={{
                                  listStyle: "none",
                                  margin: 0,
                                  padding: 0,
                                }}
                              >
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>Total Amount</b>
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>Proccessing Fee</b>
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>Down Payment</b>
                                </li>

                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>EMI Duration</b>
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>EMI Per Month</b>
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>RF off &amp; Ad (+ / -)</b>
                                </li>
                              </ul>
                            </div>
                            <div
                              className="terms-text pull-right"
                              style={{ padding: "4px 10px" }}
                            >
                              <ul
                                style={{
                                  listStyle: "none",
                                  margin: 0,
                                  padding: 0,
                                }}
                              >
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>{data?.totalAmount}</b>
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>{data?.proccesingFee}</b>
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>{data?.downPayment}</b>
                                </li>

                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>{data?.totalMonthluNumber}</b>
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>{data?.monthlyEMI}</b>
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>-</b>
                                </li>
                              </ul>
                            </div>
                          </div> */}
                        </div>
                        {/* <div
                          className="tableBox row m-0"
                          style={{
                            border: "1px solid  #E5E4E2",
                            borderRadius: "10px",
                            borderTop: 0,
                            lineHeight: "15px",
                            fontSize: "15px",
                          }}
                        >
                          <div
                            className="terms-text col-6"
                            style={{
                              lineHeight: "19px",
                              padding: "4px 10px",
                            }}
                          >
                            <ul
                              style={{
                                listStyle: "none",
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              <li
                                style={{
                                  lineHeight: "18px",
                                  fontWeight: 600,
                                  padding: 5,
                                }}
                              >
                                <b>Declaration :</b>
                              </li>
                              <li
                                style={{
                                  lineHeight: "18px",
                                  fontWeight: 600,
                                  padding: 5,
                                }}
                              >
                                we declare that this invoice show the actual
                                price on the goods described and that all
                                particulars are true and correct.
                              </li>
                            </ul>
                          </div>
                          <div
                            className="pl-left col-6"
                            style={{
                              lineHeight: "19px",
                              // borderLeft: "1px solid  #E5E4E2",
                              // borderRadius: "10px",
                              padding: 0,
                              paddingLeft: "40px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <div
                              className="terms-text"
                              style={{ padding: "4px 10px" }}
                            >
                              <ul
                                style={{
                                  listStyle: "none",
                                  margin: 0,
                                  padding: 0,
                                }}
                              >
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>Grand Total</b>
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>Total Down Payment</b>
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>Remaining Amount</b>
                                </li>
                              </ul>
                            </div>
                            <div
                              className="terms-text pull-right"
                              style={{ padding: "4px 10px" }}
                            >
                              <ul
                                style={{
                                  listStyle: "none",
                                  margin: 0,
                                  padding: 0,
                                }}
                              >
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>
                                    Rs.{" "}
                                    {parseFloat(
                                      +data?.totalAmount + +data?.proccesingFee
                                    ).toFixed(2)}
                                  </b>
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>
                                    Rs.{" "}
                                    {parseFloat(
                                      data?.totalAddaygiRakam
                                    ).toFixed(2)}
                                  </b>
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>
                                    Rs.{" "}
                                    {parseFloat(data?.addaygiRakam).toFixed(2)}
                                  </b>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div> */}
                        <div
                          className="tableBox row m-0"
                          style={{
                            border: "1px solid  #E5E4E2",
                            borderRadius: "10px",
                            borderTop: 0,
                            lineHeight: "15px",
                            fontSize: "15px",
                          }}
                        >
                          <div
                            className="col-4"
                            style={{ lineHeight: "19px", padding: "0px" }}
                          >
                            <div
                              className="terms-text"
                              style={{ padding: "4px 10px" }}
                            >
                              <h4
                                className="bank-title"
                                style={{
                                  fontFamily: '"Roboto", sans-serif',
                                  lineHeight: "1.1",
                                  color: "#222222",
                                  margin: 0,
                                  padding: 0,
                                  textAlign: "center",
                                  fontWeight: 700,
                                  width: "100%",
                                  fontSize: "14px",
                                }}
                              >
                                :: Bank Details ::
                              </h4>
                              <div
                                className="bank-detals-box"
                                style={{ display: "flex", marginTop: "2px" }}
                              >
                                <div
                                  className="bank-label"
                                  style={{ width: "115px" }}
                                >
                                  <p
                                    style={{
                                      margin: 0,
                                      lineHeight: "15px",
                                      fontWeight: 600,
                                      padding: 5,
                                      color: "#111",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Bank Name
                                  </p>
                                  <p
                                    style={{
                                      margin: 0,
                                      lineHeight: "15px",
                                      fontWeight: 600,
                                      padding: 5,
                                      color: "#111",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Branch Name
                                  </p>
                                  <p
                                    style={{
                                      margin: 0,
                                      lineHeight: "15px",
                                      fontWeight: 600,
                                      padding: 5,
                                      color: "#111",
                                      fontSize: "15px",
                                    }}
                                  >
                                    A/C No.
                                  </p>
                                  <p
                                    style={{
                                      margin: 0,
                                      lineHeight: "15px",
                                      fontWeight: 600,
                                      padding: 5,
                                      color: "#111",
                                      fontSize: "15px",
                                    }}
                                  >
                                    IFSC Code
                                  </p>
                                </div>
                                <div className="bank-fill">
                                  <p
                                    style={{
                                      margin: 0,
                                      lineHeight: "15px",
                                      fontWeight: 600,
                                      padding: 5,
                                      color: "#111",
                                      fontSize: "15px",
                                    }}
                                  >
                                    :Bank of India
                                  </p>
                                  <p
                                    style={{
                                      margin: 0,
                                      lineHeight: "15px",
                                      fontWeight: 600,
                                      padding: 5,
                                      color: "#111",
                                      fontSize: "15px",
                                    }}
                                  >
                                    :Indore
                                  </p>
                                  <p
                                    style={{
                                      margin: 0,
                                      lineHeight: "15px",
                                      fontWeight: 600,
                                      padding: 5,
                                      color: "#111",
                                      fontSize: "15px",
                                    }}
                                  >
                                    :
                                  </p>
                                  <p
                                    style={{
                                      margin: 0,
                                      lineHeight: "15px",
                                      fontWeight: 600,
                                      padding: 5,
                                      color: "#111",
                                      fontSize: "15px",
                                    }}
                                  >
                                    :
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="pl-left col-6 col-8"
                            style={{
                              lineHeight: "19px",
                              // borderLeft: "1px solid  #E5E4E2",
                              // borderRadius: "10px",
                              padding: 0,
                              paddingLeft: "40px",
                              display: "flex",
                              alignItems: "center",
                              position: "static",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="terms-text demo"
                              style={{
                                textAlign: "center",
                                padding: "4px 10px",
                              }}
                            >
                              <ul
                                style={{
                                  listStyle: "none",
                                  margin: 0,
                                  padding: 0,
                                }}
                              >
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>Care Enterprises</b>
                                </li>
                                <li
                                  style={{
                                    lineHeight: "18px",
                                    marginTop: "40px",
                                    fontWeight: 600,
                                    padding: 5,
                                  }}
                                >
                                  <b>Authorised Signature</b>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <center>
        <button
          className="btn-convert"
          style={{
            outline: "none",
            backgroundColor: "#f44336",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "16px",
            margin: "4px 2px",
            cursor: "pointer",
          }}
          onClick={handlePrint}
        >
          Print On-Board-Form
        </button>
      </center>
      <br />
      <center>
        <button
          className="btn-convert"
          style={{
            outline: "none",
            backgroundColor: "#f44336",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "16px",
            margin: "4px 2px",
            cursor: "pointer",
          }}
          onClick={handleGoBack}
        >
          Go Back
        </button>
      </center>
      <br />
    </>
  );
};

export default OnBoardInvoicePrint;
