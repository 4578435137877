import { useRef, useState } from "react";
import Header from "../components/header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import axios, { CancelToken } from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useEffect } from "react";
import Select from "react-dropdown-select";
import { DatePicker } from "reactstrap-date-picker";
import "react-datepicker/dist/react-datepicker.css";

const OnBoardForm = () => {
  const location = useLocation();
  const data = location.state;
  // sadasy ka vivaran and housing sarveshan form
  const [center, setcenter] = useState(data?.center || "");
  const [tehsil, settehsil] = useState(data?.tehsil || "");
  const [district, setdistrict] = useState(data?.district || "");
  const [centerId, setcenterId] = useState(data?.centerId || "");
  const [staffName, setstaffName] = useState(data?.staffName || "");
  const [staffId, setstaffId] = useState(data?.staffId || "");
  const [disbursementDate, setdisbursementDate] = useState(new Date());
  let s;
  if (data?.date) {
    s = new Date(data?.date);
  }

  const [date, setdate] = useState(s || "");
  const [customer, setcustomer] = useState(data?.customer || "");
  const [customerId, setcustomerId] = useState(data?.customerId || null);
  const [age, setage] = useState(data?.age || "");
  const [mobile, setmobile] = useState(data?.mobile || "");
  const [parent, setparent] = useState(data?.parent || "");
  const [parentAge, setparentAge] = useState(data?.parentAge || "");
  const [parentMobile, setparentMobile] = useState(data?.parentMobile || "");
  const [gharkaavlokan, setgharkaavlokan] = useState(data?.gharkaavlokan || "");
  // निजी
  const [selectedGharMode, setselectedGharMode] = useState(
    data?.selectedGharMode || "निजी"
  );
  const [familyDailyIncome, setfamilyDailyIncome] = useState(
    data?.familyDailyIncome || ""
  );
  const [weeklyIncome, setweeklyIncome] = useState(data?.weeklyIncome || "");
  const [monthlyIncome, setmonthlyIncome] = useState(data?.monthlyIncome || "");
  const [otherCompaniesLoan, setotherCompaniesLoan] = useState(
    data?.otherCompaniesLoan || 0
  );
  const [loanAmount, setloanAmount] = useState(data?.loanAmount || 0);
  const [dueAmount, setdueAmount] = useState(data?.dueAmount || 0);

  // product ke liye aavedan prapatra

  const [customerName, setcustomerName] = useState(data?.customerName || "");
  const [productName, setproductName] = useState(data?.productName || "");
  const [productAmount, setproductAmount] = useState(data?.productAmount || "");
  const [proccesingFee, setproccesingFee] = useState(data?.proccesingFee || 0);
  const [downPayment, setdownPayment] = useState(data?.downPayment || 0);
  const [addaygiRakam, setaddaygiRakam] = useState(data?.addaygiRakam || "");
  const [totalMonthluNumber, settotalMonthluNumber] = useState(
    data?.totalMonthluNumber || ""
  );
  const [monthlyEMI, setmonthlyEMI] = useState(data?.monthlyEMI || "");

  const navigate = useNavigate();
  const [isSubmit, setisSubmit] = useState(false);
  const { auth } = useAuth();

  // radio button

  const handleRadioChange = (event) => {
    setselectedGharMode(event.target.value);
  };

  const [customerList, setCustomerList] = useState([]);
  const [productList, setproductList] = useState([]);
  const [centerList, setcenterList] = useState([]);

  useEffect(() => {
    const customerListt = async () => {
      try {
        const response = await axios.get("/customer/list", {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setCustomerList(response.data.list);
        //console.log(response.data.list);
      } catch (err) {
        console.log(err);
      }
    };
    const itemLists = async () => {
      try {
        const response = await axios.get("/product/list", {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setproductList(response.data.list);
        //console.log(response.data.list);
      } catch (err) {
        console.log(err);
      }
    };
    const centerList = async () => {
      try {
        const response = await axios.get("/center/list/" + null, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setcenterList(response.data.list);
        //console.log(response.data.list);
      } catch (err) {
        console.log(err);
      }
    };

    customerListt();
    itemLists();
    centerList();
  }, []);

  // To insert data in table due to edit

  useEffect(() => {
    try {
      if (data) {
        let itemList = data?.data;
        console.log(data);
        itemList?.map((item, index) => {
          const updatedField = {
            sr_no: 1,
            productId: item?.productId,
            productName: item?.productName,
            hsnCode: item?.hsnCode,
            productQuantity: item?.productQuantity,
            productAmount: item?.productAmount,
            totalAmount: item?.totalAmount,
          };

          setFields((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[index] = updatedField;
            return updatedFields;
          });
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const [fields, setFields] = useState([
    {
      sr_no: 1,
      productId: "",
      productName: "",
      hsnCode: "",
      productQuantity: 1,
      productAmount: 0,
      totalAmount: 0,
    },
  ]);

  const addInputField = () => {
    const sr_no = fields.length + 1;
    const newField = {
      sr_no,
      productId: "",
      productName: "",
      hsnCode: "",
      productQuantity: 1,
      productAmount: 0,
      totalAmount: 0,
    };
    setFields((prevFields) => [...prevFields, newField]);
  };

  const removeInputField = (index) => {
    setFields((prevFields) => prevFields.filter((_, i) => i !== index));
  };

  const updateFieldValue = (index, field, value) => {
    setFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][field] = value;
      return updatedFields;
    });
  };

  const [totalAmount, settotalAmount] = useState(0);
  const [totalAddaygiRakam, settotalAddaygiRakam] = useState(0);

  useEffect(() => {
    const calculate = async () => {
      var total = 0;
      fields.map((item, index) => {
        total = total + +item?.productAmount * +item?.productQuantity;

        var tableTotal = +item?.productQuantity * +item?.productAmount;
        updateFieldValue(index, "totalAmount", tableTotal);
      });
      settotalAmount(total || 0);
      settotalAddaygiRakam(+downPayment + +proccesingFee || 0);
      setaddaygiRakam(total - downPayment);
      setmonthlyEMI(
        parseFloat(+addaygiRakam / +totalMonthluNumber || 0).toFixed(2)
      );
    };
    calculate();
  }, [fields, downPayment, proccesingFee, totalMonthluNumber]);

  const handleClose = async () => {
    navigate("/");
  };

  const Submit = async () => {
    if (
      !center ||
      !tehsil ||
      !district ||
      !centerId ||
      !staffName ||
      !staffId ||
      !date ||
      !disbursementDate
    ) {
      return swal({
        title: "चेतावनी!",
        text: `
        कृपया ग्रुप का सारा विवरण भरें`,
        icon: "warning",
      });
    }

    if (
      // !customerId ||
      !customer ||
      !age ||
      !mobile ||
      !parent ||
      !parentAge ||
      !parentMobile ||
      !gharkaavlokan ||
      // निजी
      !selectedGharMode ||
      // !familyDailyIncome ||
      // !weeklyIncome ||
      !monthlyIncome
      // !otherCompaniesLoan ||
      // !loanAmount ||
      // !dueAmount
    ) {
      return swal({
        title: "चेतावनी!",
        text: `
          कृपया सदस्य का सारा विवरण भरें`,
        icon: "warning",
      });
    }

    fields.map((item) => {
      const { productName, productQuantity, productAmount, totalAmount } = item;
      if (!productName || !productQuantity || !productAmount || !totalAmount) {
        return swal({
          title: "चेतावनी!",
          text: `
            कृपया प्रोडक्ट का सारा विवरण भरें`,
          icon: "warning",
        });
      }
    });

    if (
      // product ke liye aavedan prapatra
      !downPayment ||
      !proccesingFee ||
      !totalAmount ||
      !totalAddaygiRakam ||
      !addaygiRakam ||
      !totalMonthluNumber ||
      !monthlyEMI
    ) {
      return swal({
        title: "चेतावनी!",
        text: `
        कृपया भुगतान का सारा विवरण भरें`,
        icon: "warning",
      });
    }

    if (isSubmit) return;
    setisSubmit(true);

    try {
      const id = data?._id;
      var customerResponse;
      if (!id) {
        // Customer Added Api Called
        customerResponse = await axios.post(
          "/customer/add",
          {
            customerName: customer,
            parentName: parent,
            mobile,
            customerAge: age,
            address: gharkaavlokan,
            monthlyIncome,
          },
          {
            headers: { authorization: "bearer " + auth.token },
          }
        );
      }

      const url = data?._id ? "/onboard-form/" + id : "/onboard-form/add";
      const response = await axios.post(
        url,
        {
          center,
          tehsil,
          district,
          centerId,
          staffName,
          staffId,
          date,
          disbursementDate,
          customerId: id ? customerId : customerResponse?.data?.customerId,
          customer,
          age,
          mobile,
          parent,
          parentAge,
          parentMobile,
          gharkaavlokan,
          // निजी
          selectedGharMode,
          familyDailyIncome,
          weeklyIncome,
          monthlyIncome,
          otherCompaniesLoan,
          loanAmount,
          dueAmount,
          // product ke liye aavedan prapatra
          data: fields,
          downPayment,
          proccesingFee,
          totalAmount,
          totalAddaygiRakam,
          addaygiRakam,
          totalMonthluNumber,
          monthlyEMI,
        },
        {
          headers: { authorization: "bearer " + auth.token },
        }
      );
      console.log(response);
      if (response?.data?.success) {
        swal({
          title: "Success!",
          text: `${response.data.message}`,
          icon: "success",
        });

        navigate("/onboard/form/list");
      } else {
        swal({
          title: "Failure!",
          text: `${response.data.message}`,
          icon: "error",
        });
      }
    } catch (err) {
      swal({ title: "Error!", text: `${err.message}`, icon: "error" });
    } finally {
      setisSubmit(false);
    }
  };

  return (
    <>
      <Header page="On-Board-Form" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">
                  सद्स्य का विवरण एवं आवास सर्वेक्षण प्रपत्र
                </h3>
              </div>
              <form className="needs-validation" noValidate>
                <div className="card-body">
                  <div className="form-group">
                    <h3
                      className="card-header"
                      style={{
                        fontFamily: "monospace",
                        fontWeight: "bolder",
                      }}
                    >
                      ग्रुप का विवरण
                    </h3>
                    <br />
                    <div className="row" id="2">
                      <div className="col-md-3">
                        <label htmlFor="name">ग्रुप चूने:</label>
                        <Select
                          options={centerList}
                          searchBy="center"
                          labelField="center"
                          valueField="center"
                          multi={false}
                          loading={false}
                          handle={true}
                          addPlaceholder="Search"
                          color="blue"
                          clearable={false}
                          keepSelectedInList={true}
                          closeOnSelect={true}
                          dropdownPosition="bottom"
                          direction="ltr"
                          dropdownHeight="1000px"
                          onChange={(values) => {
                            setcenter(values[0]?.center);
                            setstaffId(values[0]?.staffId);
                            setdistrict(values[0]?.district);
                            setcenterId(values[0]?.centerId);
                            setstaffName(values[0]?.staffName);
                            setstaffId(values[0]?.staffId);
                            setdate(values[0]?.paymentDate);
                            settehsil(values[0]?.tehsil);
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="username">तहसील: </label>
                        <input
                          type="text"
                          className="form-control"
                          id="text"
                          placeholder="तहसील का नाम"
                          value={tehsil}
                          readOnly
                          onChange={(e) => settehsil(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="mobile">जिला:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="mobile"
                          value={district}
                          readOnly
                          placeholder="जिला का नाम "
                          onChange={(e) => setdistrict(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* <br /> */}
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="whom">सेंटर आईडी :</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="सेंटर"
                          placeholder="सेंटर आईडी"
                          value={centerId}
                          readOnly
                          onChange={(e) => setcenterId(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="name">Date Of Collection :</label>
                        <DatePicker
                          dateFormat="DD/MM/YYYY"
                          showClearButton={false}
                          id="to-date"
                          value={date}
                          onChange={(e) => setdate(e)}
                          placeholder="दिनांक"
                          disabled
                          // maxDate={new Date().toISOString()}
                        />
                      </div>
                    </div>
                    {/* <br /> */}
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="whom">स्टाफ का नाम:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="स्टाफ"
                          placeholder="स्टाफ का नाम"
                          value={staffName}
                          readOnly
                          onChange={(e) => setstaffName(e.target.value)}
                        />
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="name">स्टाफ आईडी:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="स्टाफ"
                          placeholder="स्टाफ आईडी"
                          value={staffId}
                          readOnly
                          onChange={(e) => setstaffId(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="name">Disbursement Date :</label>
                        <DatePicker
                          dateFormat="DD/MM/YYYY"
                          showClearButton={false}
                          id="to-date"
                          value={disbursementDate}
                          onChange={(e) => setdisbursementDate(e)}
                          placeholder="दिनांक"
                          // maxDate={new Date().toISOString()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <h3
                      className="card-header"
                      style={{
                        fontFamily: "monospace",
                        fontWeight: "bolder",
                      }}
                    >
                      सदस्य का विवरण
                    </h3>
                    <br />
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="name">सदस्य का नाम :</label>
                        {/* <Select
                          options={customerList}
                          searchBy="customerName"
                          labelField="customerName"
                          valueField="customerName"
                          multi={false}
                          name=""
                          loading={false}
                          handle={true}
                          addPlaceholder="Search"
                          color="#007bff"
                          clearable={false}
                          keepSelectedInList={true}
                          closeOnSelect={true}
                          dropdownPosition="bottom"
                          direction="ltr"
                          dropdownHeight="1000px"
                          onChange={(values) => {
                            setcustomerId(values[0]?._id);
                            setcustomer(values[0]?.customerName);
                            setage(values[0]?.customerAge);
                            setmobile(values[0]?.mobile);
                            setcustomerName(values[0]?.customerName);
                          }}
                        /> */}
                        <input
                          required
                          type="TEXT"
                          className="form-control"
                          id="सदस्य"
                          placeholder="सदस्य का नाम"
                          value={customer}
                          onChange={(e) => setcustomer(e.target.value)}
                        />
                      </div>
                      <div className="col-md-2">
                        <label htmlFor="name">आयु :</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="आयु"
                          placeholder="आयु                          "
                          value={age}
                          onChange={(e) => setage(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="name">मोबाईल :</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="मोबाईल"
                          placeholder="मोबाईल"
                          value={mobile}
                          onChange={(e) => setmobile(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="name">
                          पिता / पति / पुत्र का नाम :
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="पिता/पति/पुत्र"
                          placeholder="पिता/पति/पुत्र का नाम
"
                          value={parent}
                          onChange={(e) => setparent(e.target.value)}
                        />
                      </div>
                      <div className="col-md-2">
                        <label htmlFor="name">आयु :</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="आयु"
                          placeholder="आयु                          "
                          value={parentAge}
                          onChange={(e) => setparentAge(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="name">मोबाईल :</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="मोबाईल"
                          placeholder="मोबाईल"
                          value={parentMobile}
                          onChange={(e) => setparentMobile(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="name">घर का पता :</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="घर"
                          placeholder="घर का पता"
                          value={gharkaavlokan}
                          onChange={(e) => setgharkaavlokan(e.target.value)}
                        />
                      </div>
                      <div className="col-md-2">
                        <label
                          style={{
                            marginRight: "30px",
                            cursor: "pointer",
                            padding: "5px",
                          }}
                        >
                          <input
                            type="radio"
                            value="निजी"
                            checked={selectedGharMode === "निजी"}
                            onChange={handleRadioChange}
                          />
                          निजी{" "}
                        </label>
                        <label
                          style={{
                            marginRight: "30px",
                            cursor: "pointer",
                            padding: "5px",
                          }}
                        >
                          <input
                            type="radio"
                            value="किरायेदार"
                            checked={selectedGharMode === "किरायेदार"}
                            onChange={handleRadioChange}
                          />
                          किरायेदार{" "}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      {/* <div className="col-md-3">
                        <label htmlFor="name">परिवार की दैनिक आय :</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="परिवार"
                          placeholder="परिवार की दैनिक आय"
                          value={familyDailyIncome}
                          onChange={(e) => setfamilyDailyIncome(e.target.value)}
                        />
                      </div>
                      <div className="col-md-2">
                        <label htmlFor="name">सप्ताहिक आय :</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="सप्ताहिक आय"
                          placeholder="सप्ताहिक आय"
                          value={weeklyIncome}
                          onChange={(e) => setweeklyIncome(e.target.value)}
                        />
                      </div> */}
                      <div className="col-md-3">
                        <label htmlFor="name">मासिक आय :</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="मासिक आय"
                          placeholder="मासिक आय"
                          value={monthlyIncome}
                          onChange={(e) => setmonthlyIncome(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="name">कितनी कंपनी से ऋण लिया :</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="ऋण"
                          placeholder="कितनी कंपनी से ऋण लिया"
                          value={otherCompaniesLoan}
                          onChange={(e) =>
                            setotherCompaniesLoan(e.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-2">
                        <label htmlFor="name">ऋण की राशि :</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="ऋण की राशि"
                          placeholder="ऋण की राशि"
                          value={loanAmount}
                          onChange={(e) => setloanAmount(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="name">बकाया ऋण :</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="बकाया ऋण"
                          placeholder="बकाया ऋण"
                          value={dueAmount}
                          onChange={(e) => setdueAmount(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <h3
                      className="card-header"
                      style={{
                        fontFamily: "monospace",
                        fontWeight: "bolder",
                      }}
                    >
                      प्रोडक्ट के लिए आवेदन-प्रपत्र
                    </h3>
                    <br />
                    <table
                      id="invoice-item-table"
                      className="table table-bordered"
                    >
                      <tbody>
                        <tr>
                          <th width="5%">क्रमांक संख्या.</th>
                          <th width="25%">प्रोडक्ट का नाम</th>
                          <th width="15%">एचएसएन कोड / IMEI No</th>
                          <th width="15%">प्रोडक्ट की मात्रा</th>
                          <th width="20%">प्रोडक्ट का मूल्य</th>
                          <th width="20%">कुल राशि</th>
                          <th width="3%" />
                        </tr>
                        {fields.map((field, index) => (
                          <tr key={index}>
                            <td>
                              <span id="sr_no">{field.sr_no}</span>
                            </td>
                            <td>
                              <Select
                                options={productList}
                                searchBy="productName"
                                labelField="productName"
                                valueField="productName"
                                multi={false}
                                loading={false}
                                handle={true}
                                addPlaceholder="Search"
                                color="#007bff"
                                clearable={false}
                                keepSelectedInList={true}
                                closeOnSelect={true}
                                dropdownPosition="bottom"
                                direction="ltr"
                                dropdownHeight="1000px"
                                onChange={(values) => {
                                  updateFieldValue(
                                    index,
                                    "productId",
                                    values[0]?._id
                                  );
                                  updateFieldValue(
                                    index,
                                    "productName",
                                    values[0]?.productName
                                  );
                                  updateFieldValue(
                                    index,
                                    "productAmount",
                                    values[0]?.price
                                  );
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="hsnCode[]"
                                id={`hsnCode${field.sr_no}`}
                                data-srno={field.sr_no}
                                className="form-control input-sm number_only hsnCode"
                                value={field.hsnCode}
                                onChange={(e) => {
                                  updateFieldValue(
                                    index,
                                    "hsnCode",
                                    e.target.value
                                  );
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="productQuantity[]"
                                id={`productQuantity${field.sr_no}`}
                                data-srno={field.sr_no}
                                className="form-control input-sm number_only productQuantity"
                                value={field.productQuantity}
                                onChange={(e) => {
                                  updateFieldValue(
                                    index,
                                    "productQuantity",
                                    e.target.value
                                  );
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="productAmount[]"
                                id={`productAmount${field.sr_no}`}
                                data-srno={field.sr_no}
                                className="form-control input-sm number_only productAmount"
                                value={field.productAmount}
                                readOnly
                                onChange={(e) =>
                                  updateFieldValue(
                                    index,
                                    "productAmount",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="totalAmount[]"
                                id={`totalAmount${field.sr_no}`}
                                data-srno={field.sr_no}
                                className="form-control input-sm totalAmount"
                                value={
                                  +fields[index]?.productQuantity *
                                  +fields[index]?.productAmount
                                }
                                onChange={(e) => {
                                  var total =
                                    +fields[index]?.productQuantity *
                                    +fields[index]?.productAmount;

                                  updateFieldValue(index, "totalAmount", total);
                                }}
                              />
                            </td>

                            {fields[index]?.sr_no == 1 ? (
                              <td>
                                <button
                                  type="button"
                                  name="add_row"
                                  id="add_row"
                                  className="btn btn-success btn-xs"
                                  onClick={addInputField}
                                >
                                  +
                                </button>
                              </td>
                            ) : (
                              ""
                            )}
                            {fields[index]?.sr_no != 1 ? (
                              <td>
                                <button
                                  type="button"
                                  name="add_row"
                                  id="add_row"
                                  className="btn btn-danger btn-xs remove_row"
                                  onClick={() => removeInputField(index)}
                                >
                                  X
                                </button>
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <br />
                    {/* <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="name">आवेदक का नाम :</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="आवेदक"
                          placeholder="आवेदक का नाम"
                          value={customerName}
                          onChange={(e) => setcustomerName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="name">प्रोडक्ट चूने:</label>
                        <Select
                          options={productList}
                          searchBy="productName"
                          labelField="productName"
                          valueField="productName"
                          multi={false}
                          loading={false}
                          handle={true}
                          addPlaceholder="Search"
                          color="blue"
                          clearable={false}
                          keepSelectedInList={true}
                          closeOnSelect={true}
                          dropdownPosition="bottom"
                          direction="ltr"
                          dropdownHeight="1000px"
                          onChange={(values) => {
                            setproductName(values[0]?.productName);
                            setproductAmount(values[0]?.price);
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="name">प्रोडक्ट का मूल्य:</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="प्रोडक्ट"
                          placeholder="प्रोडक्ट का मूल्य"
                          value={productAmount}
                          onChange={(e) => setproductAmount(e.target.value)}
                        />
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="name">कुल भुगतान :</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="भुगतान"
                          placeholder="कुल भुगतान"
                          value={totalAmount}
                          readOnly
                          // onChange={(e) => setdownPayment(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="name">अग्रिम भुगतान :</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="भुगतान"
                          placeholder="अग्रिम भुगतान"
                          value={downPayment}
                          onChange={(e) => setdownPayment(e.target.value)}
                        />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="name">प्रोसेसिंग शुल्क :</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="अदायगी रकम"
                          placeholder="अदायगी रकम"
                          value={proccesingFee}
                          onChange={(e) => setproccesingFee(e.target.value)}
                        />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="name">कुल अग्रिम भुगतान :</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="कुल अग्रिम भुगतान"
                          placeholder="कुल अग्रिम भुगतान"
                          readOnly
                          value={totalAddaygiRakam}
                          // onChange={(e) => settotalAddaygiRakam(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="name">अदायगी रकम :</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="अदायगी रकम"
                          placeholder="अदायगी रकम"
                          value={addaygiRakam}
                          readOnly
                          onChange={(e) => setaddaygiRakam(e.target.value)}
                        />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="name">कुल किस्त माह :</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="कुल किस्त"
                          placeholder="कुल किस्त माह"
                          value={totalMonthluNumber}
                          onChange={(e) =>
                            settotalMonthluNumber(e.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="name">माह किस्त :</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="माह किस्त"
                          placeholder="माह किस्त"
                          value={monthlyEMI}
                          readOnly
                          onChange={(e) => setmonthlyEMI(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <center>
                  <div className="card-footer">
                    <input
                      style={{
                        backgroundColor: "#00a65a",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Save"
                      name="add_save"
                      disabled={isSubmit}
                      onClick={Submit}
                    />
                    <input
                      style={{
                        backgroundColor: "#f39c12",
                        color: "white",
                        width: "15%",
                        height: "38px",
                        border: "none",
                        marginRight: "40px",
                      }}
                      type="button"
                      value="Close"
                      name="add_save"
                      onClick={handleClose}
                    />
                  </div>
                  <StatusToast />
                </center>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default OnBoardForm;
